/* =======================ben styles================= */
.beneficiary {
  /* height: 63vh; */
  padding: 0.8rem 4rem 2rem;
  font-family: var(--font-family) !important;
  width: 100% !important;
  /* overflow-x: hidden; */
  /* background-color: pink !important; */
}

#account-input {
  position: relative !important;
}

#account-input .account-password-icon {
  position: absolute;
  top: 0.07rem;
  right: 0.08rem;
  cursor: pointer;

  /* padding: 11px; */
  padding: 0.75rem;
  background: darkgrey;
  color: orangered;
  min-width: 50px;
  text-align: center;
  border-radius: 0px 4px 4px 0px;

  /* border: 1px yellow solid; */
}

#account-input #icon-label {
  /* with label */
  top: 1.85rem;
  right: 0.07rem;
}

.account-password-gray {
  background: darkgrey;
  color: gray;
}

#custom_inut {
  position: relative;
}

.beneficiary-info-icon {
  position: absolute;
  top: 0;
  right: -1rem;
}

/* =======================ben styles================= */

.row > * {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.step-title {
  font-family: inherit !important;
  font-weight: 800 !important;
  line-height: 4rem !important;
  color: #001046 !important;
  /* font-size: 2.5rem !important; */
}

.form-input {
  border: 1px solid #0f1c3880 !important ;
  background-color: #f5f5f5 !important;
  padding: 0.5rem !important;
  /* text-align: center; */
}

.form-input::placeholder {
  color: #455a64;
}

.mobile-input {
  text-align: left !important;
  padding-left: 1rem !important;
}

#code-input-section {
  padding-right: 1% !important;
}

.form-error {
  border: 1px solid red !important;
}

#checkbox-wrapper {
  /* background: #f28021; */
  display: flex;
  align-items: center;
}

.checkbox {
  width: 2rem !important;
  height: 2rem !important;
  border: 1px solid #0f1c3880 !important;
  cursor: pointer;
  text-align: left;
  margin-right: 1rem;
}

#checkbox-label {
  color: grey !important;
  width: auto;
}

.checkbox:checked {
  background-color: #f28021 !important;
}

.checkbox:focus {
  box-shadow: none !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.btn-orange {
  background-color: #f28021 !important;
  color: #ffffff !important;
}

#reg-btn .spinner {
  right: 2em;
  top: 0.15em !important;
  width: 2.5em;
  display: block;
  position: absolute;
}

.input-password {
  position: relative;
}

.reg-password-icon {
  position: absolute;
  top: 1rem;
}

.first-password-icon {
  position: absolute;
  top: 2.5rem;
  right: 2rem;
}

.confirm-password-icon {
  position: absolute;
  top: 0.7rem;
  right: 2rem;
}

.password-icon__orange {
  color: #f28021;
}

.password-icon__blue {
  color: #001046;
}
.password-rule {
  font-size: 0.65rem;
}

.floating-label {
  display: none;
  width: 100%;
  font-size: 1rem;
  padding-right: 1rem;
  color: grey;
}

.focused {
  display: inline-block;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 0.5em;
}

input[type="password"]:-webkit-autofill {
  -webkit-text-security: disc !important;
}

@media (max-width: 768px) {
  .beneficiary {
    padding: 2rem;
  }

  .step-title {
    line-height: 2rem !important;
  }
}

@media (max-width: 300px) {
  .beneficiary {
    padding: 2rem 0.5rem;
  }
}
