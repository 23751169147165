.header {
  /* padding: 2% 15% 1% !important; */
  z-index: 1;
  /* background-color: var(--ligh-blue); */
  flex: 0 0 18%;
}

.fixed_top {
  position: fixed !important;
  top: 0 !important;
  width: 100%;
  z-index: 100;
  background-color: var(--white) !important;
  box-shadow: 1px 1px 2px 3px #00000032;
  padding-top: 0.5% !important;
  padding-bottom: 0.5% !important;
}

/* .white {
  background-color: var(--white);
} */

.transparent {
  background-color: transparent;
  /* background-color: #929bc2 !important; */
}

.logo {
  margin-right: auto !important;
  /* padding-top: 5%; */
}

.currentFlag {
  display: inline-block;
  width: 30%;
}

.dropdown {
  width: auto;
  /* padding: 0 1rem; */
}

.dropdown a {
  /* line-height: 1rem; */
  position: relative;
  z-index: 10 !important;
}

.navLink {
  line-height: 2.25rem;
  /* font-size: 1.5rem; */
  text-decoration: none;
}

.navLink:hover,
.navLink.active {
  color: var(--primary) !important;
}

.dropdown a,
.navLink {
  font-family: inherit !important;
  font-weight: var(--font-weight-medium);
  font-size: 1rem;
  color: var(--black) !important;
}

.dropdown:hover {
  color: var(--primary) !important;
}

hr {
  margin: 0 !important;
}

.company {
  width: 9rem !important;
}

.accountBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
}

.logoutIcom {
  display: flex;
  align-items: center;
  justify-content: space-around;

  color: var(--black) !important;
  background: var(--green) !important;
  border: var(--green) !important;
  border-radius: 5px !important;
  margin-right: 0.5rem;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 500;
  padding: 0.5rem 1rem;
  text-decoration: none;
}

.logoutIcom img {
  margin-right: 0.5rem;
}

.loginBtn {
  display: inline-block;
  color: var(--black) !important;
  background: var(--ligh-blue) !important;
  border: var(--ligh-blue) !important;
  border-radius: 5px !important;
  margin-right: 0.5rem;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 500;
  padding: 0.5rem 1rem;
  text-decoration: none;
}

.registerBtn {
  display: inline-block;
  background: #f28021 !important;
  border: #f28021 !important;
  color: var(--white) !important;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 500;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border-radius: 5px !important;
}
/* 
.account_link {
  text-decoration: none;
  color: currentColor !important;
} */

.closeDrawer {
  display: none !important;
}

#mobile_account_btn {
  display: flex !important;
}

@media (max-width: 1140px) {
  .header {
    padding: 1% 3% 0 !important;
  }
}

@media (max-width: 845px) {
  .header {
    padding: 2% 0 0 !important;
  }
  .logo {
    padding: 0;
  }
  .dropdown_flag .dropdown-menu {
    padding: 0 !important;
  }
  .currentFlag {
    width: 10%;
  }
}

@media (max-width: 767px) {
  .logo {
    padding-left: 10%;
    padding-top: 2%;
    /* visibility: hidden; */
  }
  /* 
  .dropdown {
    padding: 0;
  } */

  .accountBtn {
    padding-right: 1rem;
    /* flex-direction: column; */
    /* align-items: start; */
  }

  .registerBtn,
  .loginBtn {
    padding: 0.5rem;
    font-size: 0.875rem;
    /* margin-top: 1rem; */
  }
}

@media (max-width: 400px) {
  .logo {
    padding-left: 0%;
  }
}
