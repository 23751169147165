.send_money {
  background: #ffffff !important;
  margin: 5rem auto 1rem;
}

.send_money__title {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 800;
  color: var(--secondary);
  font-family: inherit;
  padding-bottom: 1rem;
}

.send_money__info {
  padding-bottom: 1rem;
  font-size: 1.25rem !important;
  font-family: inherit;
  line-height: 1.875rem;
}

/* .send_money__tabs {
  width: 100%;
} */

.send_money__tabs_list {
  width: 90%;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 2px 2px 12px 2px hsla(0, 0%, 0%, 0.25);
  margin: 2rem auto;
  padding-left: 0;

  height: 140px;
  background: #ffffff !important;
  padding-bottom: 0.5rem;
}

.tab_item {
  /* border-bottom: 2px solid var(--primary); */
  padding: 2rem;
  width: 100%;
  border-right: 1px solid #eee;
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  color: #000000;
  height: inherit;

  display: flex;
  justify-content: center;
  align-items: center;

  /* width: 25%; */
}

/* .tab_item:hover {
  border-bottom: 2px solid var(--primary);
} */

.tab_item:first-child {
  border-left: 1px solid #eee;
}

.tab_panel_container {
  width: 90%;
  margin: 3rem auto;

  font-size: 1.25rem;
  font-weight: 500;
  line-height: 30px;
  font-family: inherit;
  color: #000000;
}

.send_money_btn {
  color: #ffffff;
  line-height: 48px;
  font-size: 32px;
  font-weight: 500;
  background: var(--primary) !important;
  border-color: var(--primary) !important;
  padding: 0.5rem 1rem !important;
  margin: 1rem auto;
}

.tab_title {
  font-weight: 600;
  font-size: 1rem;
  line-height: normal;
  padding-left: 1rem;
  color: var(--black);
}

.tab_icon {
  display: inline-block;
  width: 50px;
}

.tab_icon__active {
  filter: invert(60%) sepia(18%) saturate(4399%) hue-rotate(347deg) brightness(98%) contrast(93%);
}

.tab_title__active {
  color: var(--primary);
}

/* .active_tab {
  border-bottom: 2px solid var(--primary) !important;
}

.active_tab h5 {
  color: var(--primary);
} */

.mobileMoney {
  background-color: #001046 !important;
}

/* Override default bootstarp styles for anchor , a,hover and active */
a {
  color: var(--primary) !important;
  background: none !important;
  border-radius: 0% !important;
}

.tab_active {
  border-bottom: 2px solid var(--primary) !important;
}

.tab_item:hover,
.tab_item:focus {
  border-radius: 0%;
  border-bottom: 2px solid var(--primary) !important;
}

.send_now_cta {
  margin-top: 1.5rem;
  background-color: var(--primary) !important;
  font-size: 1.5rem !important;
  border-radius: 6px !important;
  padding: 0.875rem 2rem !important;
  border: none !important;
}

.send_now_cta:focus {
  outline: none;
  /* box-shadow: 1px 1px 1px black; */
}

/* .send_now_cta:hover {
  color: var(--black) !important;
} */
