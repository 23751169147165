/* 
.kenya {
    padding: 2% 15% 1% !important;

}
.padding-left-7 {
    padding-left: 7% !important;
} */

/* .container {
  width: 75% !important;
} */



.customerJourney {
  position: absolute;
  z-index: 1;
  left: 30%;
}

.container-abso {
  position: absolute;
  overflow: hidden;
  width: 80%;
  background-color: white;
  align-items: center;
  left: 10%;

  padding-top: 49.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.light_blue {
  background-color: var(--ligh-blue);
}
