.owl-theme .owl-controlls {
  margin-top: 10px;
  text-align: center;
}

/* Styling Next and Prev buttons */

.owl-theme .owl-controlls .owl-buttons div {
  color: #fff;
  display: inline-block;
  zoom: 1;
  display: inline; /*IE7 life-saver */
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 15%;
}

.owl-theme .owl-controlls .owl-buttons .owl-prev {
  left: -40px;
  background-image: url(../images/icons/prev.png);
}
.owl-theme .owl-controlls .owl-buttons .owl-next {
  right: -40px;
  background-image: url(../images/icons/next.png);
}

/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controlls.clickable .owl-buttons div:hover {
  opacity: 1;
  text-decoration: none;
}

/* Styling Pagination*/

.owl-theme .owl-controlls .owl-page {
  display: inline-block;
  zoom: 1;
  display: inline; /*IE7 life-saver */
}
.owl-theme .owl-controlls .owl-page span {
  display: block;
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  opacity: 0.5;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: #869791;
}

.owl-theme .owl-controlls .owl-page.active span,
.owl-theme .owl-controlls.clickable .owl-page:hover span {
  opacity: 1;
}

/* If PaginationNumbers is true */

.owl-theme .owl-controlls .owl-page span.owl-numbers {
  height: auto;
  width: auto;
  color: #fff;
  padding: 2px 10px;
  font-size: 12px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
