ul.resp-tabs-list,
p {
  margin: 0px 0 0px 0;
  padding: 0px 0 0 0px;
}

.resp-tabs-list li {
  font-weight: 600;
  font-size: 13px;
  display: inline-block;
  padding: 0;
  margin: 0;
  list-style: none;
  cursor: pointer;
  float: left;
  font-family: "gothammedium", sans-serif;
}

.resp-tabs-container {
  /* padding: 20px 5%; */
  background-color: #f4f4f7;
  clear: left;
}

h2.resp-accordion {
  cursor: pointer;
  padding: 5px;
  display: none;
  background-color: #ffffff;
}

.resp-tab-content {
  /* display: none; */
  padding: 15px;
  width: 100%;
  float: left;
  background-color: #c7d6fd;
  padding: 40px;
  border-top: 1px solid rgb(25 39 66 / 8%);
}

.resp-tab-active {
  /*border: 1px solid #c1c1c1;*/
  border-bottom: none;
  margin-bottom: -1px !important;
  padding: 12px 14px 14px 14px;
  transition: all 0.9s ease;
}

.resp-tab-active {
  border-bottom: none;
  background-color: #c7d6fd !important;
  /* border-top: 6px solid #fa0000!important; */
}
.send-money-tab ul li.resp-tab-active h5 {
  color: #192742;
}
.send-money-tab2 ul li.resp-tab-active h5 {
  color: #192742;
}
/* .resp-tabs-list li.resp-tab-active:nth-child(1) span,
.resp-tabs-list li.react-tabs__tab--selected:nth-child(1) span {
  background-image: url(../images/money-process-icon1-hover.png);
} */
/* .resp-tabs-list li.resp-tab-active:nth-child(2) span,
.resp-tabs-list li.react-tabs__tab--selected:nth-child(2) span {
  background-image: url(../images/money-process-icon2-hover.png);
} */
/* .resp-tabs-list li.resp-tab-active:nth-child(3) span,
.resp-tabs-list li.react-tabs__tab--selected:nth-child(3) span {
  background-image: url(../images/money-process-icon3-hover.png);
} */
/* .resp-tabs-list li.resp-tab-active:nth-child(4) span,
.resp-tabs-list li.react-tabs__tab--selected:nth-child(4) span
{background-image:url(../images/money-process-icon4-hover.png);} */

.resp-content-active,
.resp-accordion-active {
  display: block;
}

/*.resp-tab-content {
    border: 1px solid #c1c1c1;
}*/

h2.resp-accordion {
  font-size: 13px;
  /*border: 1px solid #c1c1c1;
    border-top: 0px solid #c1c1c1;*/
  border-bottom: 1px solid #c1c1c1;
  margin: 0px;
  padding: 10px 15px;
}

h2.resp-tab-active,
h2.react-tabs__tab--selected {
  border-bottom: 0px solid #c1c1c1 !important;
  margin-bottom: 0px !important;
  padding: 10px 15px;
}

h2.resp-tab-title:last-child {
  border-bottom: 12px solid #c1c1c1 !important;
  background: blue;
}
/*-----------Vertical tabs-----------*/
.resp-vtabs ul.resp-tabs-list {
  float: left;
  width: 30%;
}

.resp-vtabs .resp-tabs-list li {
  display: block;
  padding: 15px 15px !important;
  margin: 0;
  cursor: pointer;
  float: none;
}

.resp-vtabs .resp-tabs-container {
  padding: 0px;
  background-color: #fff;
  border: 1px solid #c1c1c1;
  float: left;
  width: 68%;
  min-height: 250px;
  border-radius: 4px;
  clear: none;
}

.resp-vtabs .resp-tab-content {
  border: none;
}

.resp-vtabs li.resp-tab-active,
.resp-vtabs li.react-tabs__tab--selected {
  border: 1px solid #c1c1c1;
  border-right: none;
  background-color: #fff;
  position: relative;
  z-index: 1;
  margin-right: -1px !important;
  padding: 14px 15px 15px 14px !important;
}

.resp-arrow {
  width: 0;
  height: 0;
  float: right;
  margin-top: 3px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid #1f2b62;
  position: absolute;
  right: 10px;
}

h2.resp-tab-active span.resp-arrow,
h2.react-tabs__tab--selected span.resp-arrow {
  border: none;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 8px solid #1f2b62;
}

/*-----------Accordion styles-----------*/
h2.resp-tab-active,
h2.react-tabs__tab--selected {
  background: #ecebe1;
}
.resp-easy-accordion h2.resp-accordion {
  display: block;
}
.resp-easy-accordion .resp-tab-content {
  border: 1px solid #c1c1c1;
}

.resp-easy-accordion .resp-tab-content:last-child {
  border-bottom: 1px solid #c1c1c1 !important;
}

.resp-jfit {
  width: 100%;
  margin: 0px;
}

.resp-tab-content-active {
  display: block;
}

h2.resp-accordion:first-child {
  border-top: none;
}

/*Here your can change the breakpoint to set the accordion, when screen resolution changed*/
@media only screen and (max-width: 768px) {
  ul.resp-tabs-list {
    display: none;
  }

  h2.resp-accordion {
    display: block;
    padding: 15px;
    width: 100%;
    float: left;
  }

  .resp-vtabs .resp-tab-content {
    border: 1px solid #c1c1c1;
  }

  .resp-vtabs .resp-tabs-container {
    border: none;
    float: none;
    width: 100%;
    min-height: initial;
    clear: none;
  }
  .resp-accordion-closed {
    display: none !important;
  }
  .resp-vtabs .resp-tab-content:last-child {
    border-bottom: 1px solid #c1c1c1 !important;
  }
  .home-payments ul {
    top: 0;
  }
  .resp-tab-content {
    padding: 20px;
  }
}
