.aboutUs-content{
    /* margin-top: 11vh !important; */
    display: flex;
    flex-direction: column;
    padding: 2% 15% 1% !important;
    
}
.aboutUs-intro {
    background: var(--dark-blue);
 
}
.aboutUs-intro h1{
    color: var(--primary);
    text-transform: uppercase;
}
.aboutUs-intro h4{
    color: var(--white);
    text-transform: none;
}
.aboutUs-our-story{
    display: flex;
    flex-wrap: wrap;
}
.aboutUs-our-story div{
    height: 100px;
    margin: 5px;
    width: 50%;
}

.dark-blue{
    background: var(--dark-blue);
    color: var(--white);
}
.primary-color-bg{
    background: var(--primary) !important;
    color: white !important;
}
.primary-color {
    color: var(--primary);
}
.card-props{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 5);
    /* height: 300px !important;
    width: 350px !important; */
}
.color-dark-blue{
    color: var(--dark-blue);
}
.color-fade{
   color: rgba(0, 0, 0, 0.436) !important;
   font-weight: lighter !important;
}

.aboutus_desc p {
    color: #1E1E1E;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

@media only screen and (max-width: 575.98px) {
    .remove-border div {
        border: none !important;
    }
}
@media only screen and (max-width: 767.98px) {
    .remove-border div {
        border: none !important;
    }
}
