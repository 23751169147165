.footer {
  background: #001046;
  color: #ffffff;
  text-align: left;
  font-family: inherit;
  /* width: 90vw; */
  padding-top: 1rem;

  flex: 0 0 30%;
}

.white_logo_wrapper {
  width: 25%;
  display: flex;
  justify-content: flex-start;
}

.white_logo_wrapper img {
  display: inline-block;
  width: 100% !important;
  align-self: flex-start;
}

.footer__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.footer h1 {
  color: #f28021;
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-weight: 600;
  font-family: inherit;
  padding-bottom: 1rem;
}

.footer ul {
  padding: 0;
}

.footer ul li {
  color: var(--white);
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 400;
  font-family: inherit;

  display: flex;
  align-items: center;
}

.footer ul li span {
  font: inherit;
  text-align: start;
  display: inline-block;
}

.footer ul li i {
  padding: 0.5rem 1rem 0.5rem 0;
  font-size: 1.875rem;
}

.footer_link {
  text-decoration: none;
  color: currentColor !important;
}

.footer_link:hover {
  text-decoration: none;
  color: var(--primary) !important;
}

.version {
  color: #f28021;
  font-size: 12px;
  font-weight: 700;
  font-family: inherit;
}

@media (max-width: 1300px) and (min-width: 768px) {
  .white_logo_wrapper {
    width: 40% !important;
  }
}

@media (max-width: 768px) {
  .footer {
    padding-left: 2rem;
    overflow-x: scroll;
  }
  .footer__section {
    display: block;
  }

  .footer__section div {
    width: 20%;
  }

  .footer__section div img {
    display: inline-block;
    width: 100% !important;
  }
}
