/* =======================ben styles================= */
.sendMoney {
  /* height: 63vh; */
  padding: 0.8rem 4rem 2rem;
  font-family: var(--font-family) !important;
  width: 100% !important;
  /* overflow-x: hidden; */
  /* background-color: pink !important; */
}

#account-input {
  position: relative !important;
}

.account-password-icon {
  position: absolute;
  top: 0.18rem;
  right: 0rem;
  cursor: pointer;

  padding: 11px;
  background: darkgrey;
  color: orangered;
  min-width: 50px;
  text-align: center;
  border-radius: 0px 4px 4px 0px;
}

.account-password-gray {
  background: darkgrey;
  color: gray;
}

#custom_inut {
  position: relative;
}

.beneficiary-info-icon {
  position: absolute;
  top: 0;
  right: -1rem;
}

/* =======================ben styles================= */

.row > * {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.step-title {
  font-family: inherit !important;
  font-weight: 800 !important;
  line-height: 4rem !important;
  color: #001046 !important;
  /* font-size: 2.5rem !important; */
}

.form-input {
  border: 1px solid #0f1c3880 !important ;
  background-color: #f5f5f5 !important;
  padding: 0.5rem !important;
  /* text-align: center; */
}

.form-input::placeholder {
  color: #455a64;
}

.mobile-input {
  text-align: left !important;
  padding-left: 1rem !important;
}

#code-input-section {
  padding-right: 1% !important;
}

.form-error {
  border: 1px solid red !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.btn-orange {
  background-color: #f28021 !important;
  color: #ffffff !important;
}

#reg-btn .spinner {
  right: 2em;
  top: 0.15em !important;
  width: 2.5em;
  display: block;
  position: absolute;
}

.input-password {
  position: relative;
}

.reg-password-icon {
  position: absolute;
  top: 1rem;
}

.first-password-icon {
  position: absolute;
  top: 2.5rem;
  right: 2rem;
}

.confirm-password-icon {
  position: absolute;
  top: 0.7rem;
  right: 2rem;
}

.password-icon__orange {
  color: #f28021;
}

.password-icon__blue {
  color: #001046;
}
.password-rule {
  font-size: 0.65rem;
}

/* DOC PREVIEW MODEL */
.space-between {
  display: flex !important;
  justify-content: space-between !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.doc-container {
  width: 60vw;
  height: 400px;
  overflow-y: scroll;
}

/* ===================== RATES CALCULATOR ======================================== */
.align-left {
  text-align: left;
}

.spin .rates-spinner::before {
  content: "";
  width: 1.5em;
  height: 1.5em;
  /* border: solid 0.35em green !important; */
  border-bottom-color: rgb(231, 112, 22) !important;
}

.spin .send-money::before {
  content: "";
  /* border: solid 0.35em rgb(231, 112, 22) !important; */
  border-bottom-color: rgb(231, 112, 22);
}

@media (max-width: 768px) {
  .sendMoney {
    padding-right: 2rem;
    padding-left: 1.5rem;
  }
  .beneficiary {
    padding: 2rem;
  }

  .step-title {
    line-height: 2rem !important;
  }
}

@media (max-width: 300px) {
  .beneficiary {
    padding: 2rem 0.5rem;
  }
}

.label-value {
  position: relative;
}

.cancel-fee {
  text-decoration: line-through;
}

.discount-fee {
  position: absolute;
  top: -1.2rem;
  color: #f28021;
}

/* .cancel-fee::before {
  content: "0.00";
  text-decoration: none !important;
} */
