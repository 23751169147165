.partners {
  margin: 3rem auto;
  padding: 2rem;
  height: 100%;
}

.partners__title {
  font-size: 2rem;
  line-height: 3rem;
  color: #0f1c38;
  font-weight: 800;
  font-family: inherit;
  margin: 0 auto;
  width: 80%;
}

.partners__text {
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #0f1c38;
  font-weight: 500;
  font-family: inherit;
  margin: 0 auto 2rem;
}

.partner_logos {
  /* padding: 4rem 0 2rem; */
}

.partner {
  width: 60% !important;
}

.partner img {
  display: inline-block;
}

@media (max-width: 768px) {
  .partner {
    /* width: 100% !important; */
  }
}

.emq_logo img:hover {
  content: url("../../../../assets/images/partners/SVGs/Emq-01.svg");
}

.terrapay_logo img:hover {
  content: url("../../../../assets/images/partners/SVGs/terrapay-01.svg");
}

.safcom_logo img:hover {
  content: url("../../../../assets/images/partners/SVGs/safcom-01.svg");
}
.mastercard_logo img:hover {
  content: url("../../../../assets/images/partners/SVGs/master_card-01.svg");
}
