.header_bg {
  min-height: 70vh;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* flex-wrap: wrap; */
background: linear-gradient(to top, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)),
    url("../../../../assets/images/map-bg.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left 0 bottom 10%;
  margin-top: -3rem;
  /* padding-left: 10%; */
}

.header_bg {
  animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* .cover_info {
  width: auto;
} */

.cover_title__text {
  /* font-size: 3rem;
  font-weight: var(--font-weight-large);
  line-height: 4.5rem;
  width: 40%;
  text-align: left;
  font-family: inherit; */
  color: var(--black);
  /* flex: 0 0 auto; */
  /* background-color: pink; */
  margin-right: 0.5rem;
}

.cta_right__btn {
  background: var(--primary) !important;
  border-color: var(--primary) !important;
  border-radius: 6px;
  font-size: 1.8rem !important;
  line-height: 2rem !important;
  font-weight: 500 !important;
  float: left;
  padding: 0.5rem 1rem !important;
  font-family: inherit;
  margin: 1rem 0;
}

@media (max-width: 992px) {
  .header_bg {
    background-position: left 30% bottom 70%;
    padding: 1rem;
  }

  .cover_title__text {
    width: auto !important;
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
}

@media (max-width: 768px) {
  .header_bg {
    flex-wrap: wrap;
    background-size: cover;
    padding-top: 3rem;
    height: auto;
    flex-direction: column;
  }

  /* .cover_info {
    width: 80%;
  } */

  .cover_title__text {
    margin-right: 0;
    text-align: center !important;
    padding-top: 1rem;
    /* width: 80%; */
  }
}

@media (max-height: 950px) {
  .header_bg {
    margin-top: 0;
  }
}

@media (max-height: 850px) {
  .header_bg {
    /* height: 80vh; */
  }

  .cover_title__text {
    font-size: 2rem;
    line-height: 3rem;
  }
}

@media (max-width: 540px) {
  .header_bg {
    margin-top: 0;
    padding-top: 0;
  }

  .cover_title__text {
    font-size: 2rem;
    line-height: 3rem;
  }
}

@media (max-width: 280px) {
  .cover_title__text {
    width: auto;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
