.registration {
  /* height: 63vh; */
  padding: 4rem;
  font-family: var(--font-family) !important;
}

.row > * {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.step-title {
  font-family: inherit !important;
  font-weight: 800 !important;
  line-height: 4rem !important;
  color: #001046 !important;
  /* font-size: 2.5rem !important; */
}

.form-input {
  border: 1px solid #0f1c3880 !important ;
  background-color: #f5f5f5 !important;
  padding: 0.5rem !important;
  text-align: center;
}

.form-input::placeholder {
  color: #455a64;
}

.mobile-input {
  text-align: left !important;
  padding-left: 1rem !important;
}

#code-input-section {
  padding-right: 1% !important;
}

.form-error {
  border: 1px solid red !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.btn-orange {
  background-color: #f28021 !important;
  color: #ffffff !important;
}

#reg-btn .spinner {
  right: 2em;
  top: 0.15em !important;
  width: 2.5em;
  display: block;
  position: absolute;
}

.input-password {
  position: relative;
}

.reg-password-icon {
  position: absolute;
  top: 1rem;
}

.first-password-icon {
  position: absolute;
  top: 2.5rem;
  right: 2rem;
}

.confirm-password-icon {
  position: absolute;
  top: 0.7rem;
  right: 2rem;
}

.password-icon__orange {
  color: #f28021;
}

.password-icon__blue {
  color: #001046;
}
.password-rule {
  font-size: 0.65rem;
}

.text-orange {
  color: #f28021;
}

@media (max-width: 768px) {
  .registration {
    padding: 2rem;
  }

  .step-title {
    line-height: 2rem !important;
  }
}

@media (max-width: 300px) {
  .registration {
    padding: 2rem 0.5rem;
  }
}
