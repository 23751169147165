.popup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup_content {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  position: absolute;
  cursor: grab;
}

.popup_content:active {
  cursor: grabbing;
}

.close_btn {
  position: absolute;
  top: -3px;
  right: -10px;
  height: 1.55rem;
  width: 1.55rem;
  font-size: .687rem;
  line-height: 1.25rem;
  background: #0d022b;
  color: #fff;
  border-radius: 50%;
}

.advert_popup {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.advert_popup img {
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
}
@media only screen and (max-width: 600px) {
  .popup_content {
    width: auto;
    max-width: none;
  }

  .close_btn {
    font-size: 20px;
    top: 5px;
    right: 5px;
  }
}
/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .popup_content {
    width: 80%;
    max-width: 500px;
  }
}

/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .popup_content {
    width: 70%;
    max-width: 600px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .popup_content {
    width: 60%;
    max-width: 700px;
  }
}
