.stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80% !important;
  /* background-color: aliceblue; */
  padding: 2rem 2rem 4rem;
  margin: 0 auto;
  height: auto;
}

.stepper-item {
  background-color: #9c9c9c !important;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}
/* .stepper-item:not(:first-child) {
  cursor: pointer;
} */

.stepper-label {
  position: absolute;
  top: 2.5rem;
  width: 500px;
  /* width: 100%; */
}

.progress-bar {
  flex: auto;
  border: 1px solid #9c9c9c;
}

.bg-active {
  background-color: #e96c04 !important;
}

.text-active {
  color: #e96c04;
}

.progress-bar-active {
  border: 1px solid #e96c04;
}

@media (max-width: 1550px) {
  .stepper-label {
    width: 300px;
  }
}

@media (max-width: 768px) {
  .stepper {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .stepper-label {
    width: 200px;
  }
}

@media (max-width: 576px) {
  .stepper-item {
    width: 1.5rem;
    height: 1.5rem;
  }

  .stepper-label {
    font-size: 0.675rem;
    width: 100px;
  }
}

@media (max-width: 400px) {
  .stepper-label {
    width: 100% !important;
  }
}

@media (max-width: 300px) {
  .stepper {
    width: 90vw;
    padding-left: 1rem;
    overflow-x: scroll;
  }
}
