.why_us {
  /* width: 80%;
  margin: 6rem auto;
  padding: 0 1rem; */
}

.why_us_Bg{
  background-image: url('../../../../assets/images/MultiRacial.png');
  background-repeat: no-repeat;
  background-size:contain;
  background-position:  right -5px;
}

.why_us__title {
  color: var(--primary);
  font-size: 2.5rem !important;
  line-height: 3.75rem;
  font-weight: 900;
  font-family: inherit;
}

.why_us__text {
  text-align: center;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-family: inherit;
  padding: 1rem 0 2rem;
  color: var(--black);
}

.why_us__card {
  border-radius: 18px !important;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.25);
  width: 90% !important;
  border: none !important;
  padding: 10% 0;
  height: 80% !important;
 
  /* height: 28.875rem !important; */
}


.why_us__card:hover {
  background: #001046 !important;
  color: #ffffff !important;
  

}

.why_us__card_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.why_us__card_title {
  font-weight: 900 !important;
  font-size: 2rem !important;
  line-height: 3rem !important;
  font-family: inherit !important;
  /* background-color: teal; */
}

.why_us__card div {
  font-size: 1.5rem;
  line-height: 2rem;
  font-family: inherit;
  font-weight: 900;
  /* background-color: pink; */
}

.why_us__bg__white {
  background: #fff !important;
  color: #ffffff;
}
.why_us_primary_color {
  color: var(--primary);
}

.why_us__bg__navy_blue {
  background: #001046 !important;
}

.card_icon_wrapper {
  width: 60%;
  /* height: 70%; */
  margin: 0 !important;
}
/* @media (max-width: 1500px) {
  .why_us__card {
    height: 20rem !important;
  }
} */

.image_wrapper {
  position: relative;
  /* background-color: #001046; */
}

.image_wrapper img{
  left: 0 !important;
  bottom: 0 !important;
}


/* @media (max-width: 992px) {
  .why_us {
    width: 100%;
    margin: 1rem auto;
  }
}



@media (max-width: 576px) {
  .why_us__card {
    padding: 0 !important;
  }
  .why_us__card_title {
    font-size: 1.3rem !important;
    line-height: 1.5rem !important;
  }
  

  .card_icon_wrapper {
    width: 50%;
  }
} */


