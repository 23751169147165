.ug-cont {
  display: flex;
  flex-direction: column;
}
.cover_title__text{
 color: var(--black) !important;
}

.header_bg {
  height: fit-content;
  width: 100%;
  background-repeat: no-repeat;
  background: url("../../../../assets/images/UG-bg.png");
  background-position: left 30% top 10%;
  background-size: cover;
}

.cover_title__text {
  /* padding-left: 5rem; */
  color: var(--white);
  width: 100%;
  /* margin-bottom: 5rem; */

  
}
.exchangeCardDiv .card {
  margin-top: -12rem;
}

@media only screen and (max-width: 767px) {
  .cover_title__text {
    padding-left: 0;

    width: 100%;
    padding-top: 5rem;
  }

  .header_bg {
    height: max-content;
    padding-bottom: 1.5rem;
  }
}
