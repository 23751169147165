/* clearfix */
.owl-carousel .owl-wrapper:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}
/* display none until init */
.owl-carousel{
	position: relative;
	width: 100%;
	-ms-touch-action: pan-y;
}
.owl-carousel .owl-wrapper{
	display: none;
	position: relative;
	-webkit-transform: translate3d(0px, 0px, 0px);
	-webkit-perspective: 1000;
}
.owl-carousel .owl-wrapper-outer{
	overflow: hidden;
	position: relative;
	width: 100%;
}
.owl-carousel .owl-item{float: left;text-align: center;padding: 0 5px;}
.owl-controlls .owl-page,
.owl-controlls .owl-buttons div{
	cursor: pointer;
	border:none;
}
.owl-controlls {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* mouse grab icon */
.grabbing {
    cursor:url(grabbing.png) 8 8, move;
}

/* fix */
.owl-carousel  .owl-wrapper,
.owl-carousel  .owl-item{
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility:    hidden;
	-ms-backface-visibility:     hidden;
}




.owl-item .item img{
    width: 180px;
		filter: grayscale(100%);
    transition: all 0.9s ease 0s;

}
.owl-item .item:hover img{
	filter: grayscale(0%);
	transition: all 0.9s ease 0s;
}




@media handheld, only screen and (max-width: 768px) {
.owl-item .item img{width:120px;}
}