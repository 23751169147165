#swift-secureMoney {
  color: #001046;
}
.mobile-money {
  color: var(--primary);
}

.cashpickup-banner {
  /* height: 55vh; */
  /* background: url("../../../../assets/images/cash-pick-up.jpg"); */
  background-size: cover;
  background-position: left 0 bottom 50%;
}

@media (max-width: 768px) {
  #swift-secureMoney {
    width: 100% !important;
    padding: 0 !important;
  }

  #mobile-money-txt {
    width: 100% !important;
  }
}
