
#swift-secureMoney {
    color:  #001046;
    
}
.mobile-money {
    color: var(--primary);
}

.bankdeposit-banner{
    height: 55vh;
    background: url("../../../../assets/images/bank-deposit-banner.jfif");
    background-size: cover;
    background-position: left 0 bottom 50%;
}

@media (max-width: 768px) {
    #swift-secureMoney {
        width: 100% !important;
        padding: 0 !important;
    }

    #mobile-money-txt {
        width: 100% !important;
    }

}