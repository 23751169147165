.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#extra-field {
  width: 49%;
  /* margin-left: 1%; */
}

.mr {
  margin-right: 1% !important;
}

.ml {
  margin-left: 1% !important;
}

@media (max-width: 768px) {
  #extra-field {
    width: 100%;
  }
  .mr,
  ml {
    margin: 0;
  }
}

@font-face {
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}

input:disabled,
[disabled] {
  cursor: not-allowed !important;
}

#custom-select .react-select__control--is-disabled {
  cursor: not-allowed !important;
  pointer-events: visible !important;
}

/* ============== beneficiary features ====================== */
.link-text {
  text-decoration: none;
}

/* ====== logout modal =========================================== */

.modal {
  display: block !important;
  width: auto !important;
  height: auto !important;
  border-radius: 0.5rem !important;
  border-width: 0 !important;
}

.modal-header {
  justify-content: flex-end !important;
}

/* ============================================= */
/* LOGIN */
/* ============================================= */

.login-error {
  display: flex;
  justify-content: center;
}

#btn-pointer {
  cursor: pointer;
}

@media (max-width: 768px) {
  .login-text {
    padding-top: 1.2rem;
    font-size: 1.5rem;
  }
}

/* ============================================= */
/* FOOTER */
/* ============================================= */

.version {
  color: #f28021;
  font-size: 12px;
  font-weight: 700;
  font-family: inherit;
}

/* ============================================= */
/* CUSTOM SLIDER */
/* ============================================= */
.flag_img {
  background-image: url("./assets/images/flags_css_sprites.png");
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
}

.text-primary {
  color: #f28021 ;
}
