


.google_apple_store {
  color: #263238 !important;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-decoration: none !important;
  
}