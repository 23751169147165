/* =================================================================== 
support*/

.support-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}

.support-bg {
  background: url("../../../assets//images/support-bg.png");
  height: 65vh;
  width: 100%;
  background-position: left 10% bottom 15%;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  align-items: center;
  /* justify-content: start; */
  margin: 0 auto;
  flex-wrap: wrap;
}
.support-bg h1 {
  width: 50%;
  padding-left: 10%;
  color: #000000;
  line-height: 6rem;
  font-size: 4rem;
  font-weight: 800;
  font-family: inherit;
  text-align: left;
}

.support__info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
  padding-bottom: 0;
  flex-wrap: wrap;
  height: 100%;
}

.support__list {
  width: 40%;
  padding: 2rem 0;
  text-align: start;
  padding-left: 98px;
  height: 100%;

  list-style: none;
  counter-reset: my-awesome-counter;
}

.support__list li {
  list-style-type: number;
  list-style-position: outside !important;
  padding: 0.7rem 0;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  position: relative;
  margin-bottom: 1px;
}

.support__list li span {
  font: inherit;
  position: relative;
  bottom: 0;
  display: inline-block;
}

.support__right-bg {
  background: url("../../../assets/images/how-to-send-money.png");
  background-position: center;
  width: 60%;
  background-size: contain;
  background-repeat: no-repeat;
  height: 1112px;
}

.support__list ::marker {
  font-weight: 600;
  color: #f28021;
  font-size: 4rem;
  text-align: end;
}

.ref {
  padding-top: 6rem;
}

@media (max-width: 801px) {
  .support-bg {
    height: 350px;
  }
  .support-bg h1 {
    padding-left: 2%;
    line-height: 1.5rem;
    font-size: 1.5rem;
  }
  .support__list {
    width: 100%;
    padding: 2rem;
  }
  .support__list li {
    font-size: 1.2rem;
    line-height: 25px;
    font-weight: 500;
  }

  .support__right-bg {
    width: 100%;
    height: 700px;
  }
}

@media (max-width: 600px) {
  .support__list li {
    font-size: 10px;
    line-height: 15px;
    font-weight: 500;
  }

  .support__right-bg {
    height: 500px;
  }

  .support__list ::marker {
    font-weight: 500;
    font-size: 2.5rem;
    text-align: start;
    justify-self: center;
  }
}

@media (max-width: 480px) {
  .support-bg {
    height: 200px;
    width: 100%;
  }

  .support__right-bg {
    height: 200px;
  }

  .support__list {
    width: 100%;
    padding: 2rem 0;
    text-align: start;
    padding-left: 10px;
  }
  .support__list ::marker {
    font-weight: 600;
    font-size: 30px;
  }

  .support__info {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 1.5rem;
    padding-bottom: 0;
    flex-wrap: wrap;
    height: 100%;
  }

  .support-bg h1 {
    width: 50%;
    padding-left: 2%;
    color: #000000;
    line-height: 1rem;
    font-size: 1rem;
    font-weight: 800;
    font-family: inherit;
  }
}
