.full-page-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.full-page-video {
  position: absolute; /* Set position to absolute */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  object-fit: cover; /* Cover the entire area */
}

.wave-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 7rem;
  color: white;
  font-family: "Poppins", sans-serif;
  white-space: nowrap;
  text-transform: uppercase;

  /* Add multiple text-shadow layers for 3D effect */
  text-shadow: 1px 1px 0 #f2a53c, /* Lighter shade */ 2px 2px 0 #f28021, /* Main color */ 3px 3px 0 #c15e1d,
    /* Darker shade */ 4px 4px 0 #b04d17, /* Darker shade */ 5px 5px 15px rgba(0, 0, 0, 0.5); /* Final shadow with blur for depth */
}

.wave-text span {
  display: inline-block;
  animation: wave 1.5s ease-in-out infinite;
}

.wave-text span:nth-child(1) {
  animation-delay: 0.1s;
}
.wave-text span:nth-child(2) {
  animation-delay: 0.2s;
}
.wave-text span:nth-child(3) {
  animation-delay: 0.3s;
}
.wave-text span:nth-child(4) {
  animation-delay: 0.4s;
}
.wave-text span:nth-child(5) {
  animation-delay: 0.5s;
}
.wave-text span:nth-child(6) {
  animation-delay: 0.6s;
}
.wave-text span:nth-child(7) {
  animation-delay: 0.7s;
}
.wave-text span:nth-child(8) {
  animation-delay: 0.8s;
}
.wave-text span:nth-child(9) {
  animation-delay: 0.9s;
}
.wave-text span:nth-child(10) {
  animation-delay: 1s;
}
.wave-text span:nth-child(11) {
  animation-delay: 1.1s;
}

@keyframes wave {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Rwanda flag colors */
.blue {
  color: #00a1de; /* Blue from Rwanda flag */
}

.yellow {
  color: #fad201; /* Yellow from Rwanda flag */
}

.green {
  color: #007849; /* Green from Rwanda flag */
}

/* Media Queries for Responsiveness */

/* Small devices (phones, 600px and below) */
@media (max-width: 600px) {
  .wave-text {
    font-size: 2rem; /* Scale down for smaller screens */
  }
}

/* Medium devices (tablets, 768px and below) */
@media (max-width: 768px) {
  .wave-text {
    font-size: 3rem; /* Adjust for tablet screens */
  }
}

/* Large devices (desktops, 1024px and above) */
@media (min-width: 1024px) {
  .wave-text {
    font-size: 5rem; /* Keep the original size for larger screens */
  }
}
