@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css?family=Abril+Fatface");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&family=Poppins:wght@400;500;600;700;800;900&display=swap");

:root {
  /* color */
  --primary: #f28021;
  --secondary: #001046;
  --black: #000000;
  --white: #ffffff;
  --ligh-blue: #bbcfff;
  --gray: #9c9c9c;
  --dark-blue: #152849;
  --green: #09e947;

  /* font */
  --font-weight-medium: 500;
  --font-weight-large: 800;
  --font-family: "Poppins", sans-serif;
  --primary-font-family: "Nunito Sans", sans-serif;
  --border-dark-gray: 1px solid rgba(15, 28, 56, 0.5);
}

.row {
  margin: 0 !important;
}

.landing-page {
  min-height: 100vh;
  background: #ffffff !important;
  font-family: var(--primary-font-family);
  font-family: var(--font-family) !important;
  width: 100% !important;
  position: relative;

  display: flex;
  flex-direction: column;
  /* font-size: 62.5% !important; */
}

.main-content {
  /* width: 8-0vw; */
  flex: 1;
}

@media (max-width: 350px) {
  .landing-page {
    overflow-x: scroll;
    width: 350px !important;
  }
}

/* ========================================================================================================= */
/*  HEADER BOOTSTRAP STYLES */
/* ========================================================================================================= */

.dropdown-menu {
  padding: 0 !important;
  min-width: 9rem !important;
}

.dropdown-item {
  padding: 0.5rem 1rem !important;
}

.dropdown-item:hover {
  color: var(--primary) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--primary) !important;
  background-color: transparent !important;
  /* padding: 0.5rem 1rem !important; */
}

.navbar-expand-md .navbar-nav .dropdown-menu {
  position: absolute;
  top: -0.1rem;
  /* left: 0.35rem; */
  left: -1rem;
  padding-top: 2.5rem !important;
  z-index: 5;
}
.offcanvas {
  padding-left: 2rem !important;
}

.h-title {
  font-size: 2.875rem;
  font-weight: var(--font-weight-large);
  line-height: 4.5rem;
  width: 40%;
  text-align: left;
  font-family: inherit;
}

@media (min-width: 1200px) {
  .offcanvas.offcanvas-end {
    margin-left: auto !important;
    padding-left: 20% !important;
  }
}
/* ========================================================================================================= */
/* END OF HEADER BOOTSTRAP STYLES */
/* ========================================================================================================= */

/* ========================================================================================================= */
/*  EX-RATE CARD BOOTSTRAP STYLES */
/* ========================================================================================================= */

.form-control {
  border: var(--border-dark-gray) !important;
  border-right: none !important;
  padding: 1.5rem !important;
}
.input-group-text {
  background: transparent !important;
}

.input-group-text .form-select {
  border: none !important;
  border-radius: 0;
  border-left: 1px solid var(--gray) !important;
}

.form-control:focus {
  /* color: #212529; */
  background-color: var(--white);
  border-color: none !important;
  outline: none;
  box-shadow: none !important;
}

.form-select:focus {
  border-color: none !important;
  outline: none;
  box-shadow: none !important;
}

#dropdown-menu-align-right {
  background: transparent;
}
/* .select  */
span .dropdown-menu {
  height: 400px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
}

.dropdown-toggle::after {
  box-sizing: border-box;
  height: 8px;
  width: 8px;
  border-style: solid !important;
  border-color: rgb(52, 58, 64) !important;
  border-width: 0px 2px 2px 0px !important;
  transform: rotate(45deg);
  transition: border-width 150ms ease-in-out;
  /* border-color: aliceblue !important; */
}

#receiver-input {
  border: none !important;
  border-left: 1px solid var(--gray) !important;
  border-radius: 0 !important;
  padding: 0.5rem 0.675rem !important;
  padding-right: 0.2rem !important;
}

.input-group .btn {
  background-color: transparent !important;
  border: none;
}

@media (max-width: 1200px) {
  .form-control {
    padding: 0.375rem 0.75rem !important;
  }
}

@media (max-height: 850px) {
  .form-control {
    /* padding: 0.875rem !important; */
  }
}

@media (max-width: 540px) {
  .form-control {
    /* padding: 0.875rem !important; */
  }
}

@media (max-width: 280px) {
  .form-control {
    /* padding: 0.35rem !important; */
    font-size: 0.65rem !important;
  }

  .input-group-text .form-select {
    font-size: 0.6rem !important;
  }
}

/* ========================================================================================================= */
/* END OF EX-RATE CARD BOOTSTRAP STYLES */
/* ========================================================================================================= */

/* ========================================================================================================= */
/*  PARTNERS BOOTSTRAP STYLES */
/* ========================================================================================================= */

.slick-slider .slick-track,
.slick-slider .slick-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ========================================================================================================= */
/* END OF EX-RATE CARD BOOTSTRAP STYLES */
/* ========================================================================================================= */

/* ========================================================================================================= */
/*   TESTIMONIAL BOOTSTRAP STYLES */
/* ========================================================================================================= */

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: var(--primary) !important;
}

.carousel-control-next,
.carousel-control-prev {
  display: none !important;
}

.carousel-inner {
  width: 100% !important;
}

@media (max-width: 768px) {
  .carousel {
    overflow-x: scroll;
  }

  .carousel-inner {
    width: 100vw !important;
  }

  .carousel-item {
  }

  .carousel-item img {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .carousel-inner {
    width: 550px !important;
  }
}

/* ========================================================================================================= */
/* END OF TESTIMONIAL BOOTSTRAP STYLES */
/* ========================================================================================================= */
