.pagination-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.item {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 5px;
}

.pagination {
  display: flex;
  margin-top: 20px;
}

.page-button {
  cursor: pointer;
  padding: 5px 10px;
  margin: 0 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  outline: none;
}

.page-button:hover {
  background-color: #ddd;
}

.page-button.active {
  background-color: #007bff;
  color: #fff;
}
