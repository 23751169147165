@font-face {
  font-family: gothammedium;
  src: url("fonts/gothammediumitalic-webfont.woff2") format("woff2"),
    url("fonts/gothammediumitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: gothammedium;
  src: url("fonts/gothammedium-webfont.woff2") format("woff2"), url("fonts/gothammedium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: gothammedium;
  src: url("fonts/gothammedium_1-webfont.woff2") format("woff2"),
    url("fonts/gothammedium_1-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: gothamlight_italic;
  src: url("fonts/gothamlightitalic-webfont.woff2") format("woff2"),
    url("fonts/gothamlightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: gothamlight;
  src: url("fonts/gothamlight-webfont.woff2") format("woff2"), url("fonts/gothamlight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: gothambook_italic;
  src: url("fonts/gothambookitalic-webfont.woff2") format("woff2"),
    url("fonts/gothambookitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: gothambook;
  src: url("fonts/gothambook-webfont.woff2") format("woff2"), url("fonts/gothambook-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: gothambold_italic;
  src: url("fonts/gothambolditalic-webfont.woff2") format("woff2"),
    url("fonts/gothambolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: gothambold;
  src: url("fonts/gothambold-webfont.woff2") format("woff2"), url("fonts/gothambold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: gothamxlightitalic;
  src: url("fonts/gotham-xlightitalic-webfont.woff2") format("woff2"),
    url("fonts/gotham-xlightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: gothamxlight;
  src: url("fonts/gotham-xlight-webfont.woff2") format("woff2"), url("fonts/gotham-xlight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: gothamultraitalic;
  src: url("fonts/gotham-ultraitalic-webfont.woff2") format("woff2"),
    url("fonts/gotham-ultraitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: gothamthinitalic;
  src: url("fonts/gotham-thinitalic-webfont.woff2") format("woff2"),
    url("fonts/gotham-thinitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: gothamthin;
  src: url("fonts/gotham-thin-webfont.woff2") format("woff2"), url("fonts/gotham-thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: gothamlight;
  src: url("fonts/gotham-light-webfont.woff2") format("woff2"), url("fonts/gotham-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: gothambookitalic;
  src: url("fonts/gotham-bookitalic-webfont.woff2") format("woff2"),
    url("fonts/gotham-bookitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: gothambold;
  src: url("fonts/gotham-bold-webfont.woff2") format("woff2"), url("fonts/gotham-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: gothamblack;
  src: url("fonts/gotham-black-webfont.woff2") format("woff2"), url("fonts/gotham-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
ul,
li {
  margin: 0px;
  padding: 0px;
  border: 0px;
  font-size: 100%;
  background: transparent;
  text-decoration: none;
  quotes: none;
  list-style: none;
}
html,
body {
  height: 100%;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0px;
  padding: 0px;
  line-height: 1;
  opacity: 1;
  text-size-adjust: none;
  overflow: auto;
}
.preload {
  transition: none 0s ease 0s !important;
}
::selection {
  color: rgb(255, 255, 255);
  background: rgb(0, 0, 0);
}
article,
aside,
footer,
header,
hgroup,
nav,
section,
figure,
figcaption {
  display: block;
  margin: 0px;
}
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
input,
textarea {
  border-radius: 0px;
  font-family: gothammedium, sans-serif;
}
input[type="submit"],
input[type="button"] {
  appearance: none;
}
button {
  font-family: gothammedium, sans-serif;
}
span {
  font-family: gothammedium, sans-serif;
}
blockquote::before,
blockquote::after,
q::before,
q::after {
  content: none;
}
:focus {
  outline: 0px;
}
ins {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}
table {
  border-collapse: collapse;
  border-spacing: 0px;
}
tfoot td {
  border: none !important;
}
*,
::after,
::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  margin: 0px;
  padding: 0px;
}
a {
  cursor: pointer;
  transition: all 0.9s ease 0s;
  outline: none;
  text-decoration: none;
  font-family: gothammedium, sans-serif;
}
button {
  cursor: pointer;
  transition: all 0.9s ease 0s;
  outline: none;
  border: none;
  text-decoration: none;
  font-family: gothammedium, sans-serif;
}
.clearfloat {
  clear: both;
}
body h1 {
  font-size: 32px;
  font-family: gothamblack, sans-serif;
}
body h2 {
  font-size: 20px;
  font-family: gothamblack, sans-serif;
}
body h3 {
  font-size: 18px;
  font-family: gothamblack, sans-serif;
}
body h4 {
  font-size: 16px;
  font-family: gothambold, sans-serif;
  text-align: center;
}
body h5 {
  font-size: 14px;
  font-family: gothambold, sans-serif;
}
body h6 {
  font-size: 12px;
  font-family: gothammedium, sans-serif;
}
body p {
  font-size: 13px;
  line-height: 22px;
  font-weight: normal;
  font-family: gothambook, sans-serif;
  margin: 0px;
  padding: 0px;
}
img {
  width: 100%;
  display: block;
  transition: all 1s ease 0s;
  margin: auto;
}
.js-count-particles {
  font-size: 1.1em;
}
#stats,
.count-particles {
  user-select: none;
  margin-top: 5px;
  margin-left: 5px;
}
#stats {
  border-radius: 3px 3px 0px 0px;
  overflow: hidden;
}
.count-particles {
  border-radius: 0px 0px 3px 3px;
}
#particles-js {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: relative;
  z-index: -1;
}
.container {
  width: 100%;
  float: left;
}
.header-wrapper {
  /* flex: 0 0  10%; */
  width: 100%;
  float: left;
  position: relative;
}
.header-wrapper::after {
  background: linear-gradient(-45deg, #1b223a 16px, transparent 0), linear-gradient(45deg, #1b223a 16px, transparent 0);
  background-position: left-bottom;
  background-repeat: repeat-x;
  background-size: 14px 25px;
  content: " ";
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 8px;
}
.desktop-header-wrapper {
  width: 100%;
  float: left;
  background-image: linear-gradient(to right, rgb(243, 246, 255), rgb(232, 232, 232));
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.desktop-header-wrapper2 {
  display: flex;
}
.logo-wrapper {
  width: 10%;
  float: left;
}
.logo-wrapper img {
  width: 76px;
}
.desktop-lists {
  width: 50%;
  float: left;
  padding: 20px 0px 0px;
}
.desktop-lists ul {
  width: 100%;
  float: left;
  text-align: left;
}
.desktop-lists ul li {
  display: inline-block;
  padding: 0px 0px;
}
.desktop-lists ul li a {
  font-size: 11px;
  color: rgb(26, 34, 58);
  padding: 10px 8px;
  float: left;
  text-transform: uppercase;
  position: relative;
}
.desktop-lists ul li a::before,
.desktop-lists ul li a::after {
  height: 14px;
  width: 14px;
  position: absolute;
  content: "";
  transition: all 0.35s ease 0s;
  opacity: 0;
}
.desktop-lists ul li a::before {
  right: 0px;
  top: 0px;
  border-right: 1px solid rgb(208, 208, 208);
  border-top: 1px solid rgb(208, 208, 208);
  transform: translate(-100%, 50%);
}
.desktop-lists ul li a::after {
  left: 0px;
  bottom: 0px;
  border-left: 1px solid rgb(208, 208, 208);
  border-bottom: 1px solid rgb(208, 208, 208);
  transform: translate(100%, -50%);
}
.desktop-lists ul li a:hover,
.desktop-lists ul li a.active-header-link {
  color: rgb(231, 112, 22);
}
.desktop-lists ul li a:hover::before,
.desktop-lists ul li a.active-header-link::before,
.desktop-lists ul li a:hover::after,
.desktop-lists ul li a.active-header-link::after {
  transform: translate(0%, 0%);
  opacity: 1;
}
.header-account-wrapper a:hover,
.header-account-wrapper a.active-header-link {
  color: rgb(231, 112, 22);
}
.header-account-wrapper a:hover::before,
.header-account-wrapper a.active-header-link::before,
.header-account-wrapper a:hover::after,
.header-account-wrapper a.active-header-link::after {
  transform: translate(0%, 0%);
  opacity: 1;
}
.header-account-wrapper {
  width: auto;
  float: left;
  text-align: right;
  padding: 20px 20px 0px;
}
.header-account-wrapper a {
  display: inline-block;
  padding: 0px 10px 0px 0px;
  position: relative;
  font-size: 11px;
}
.header-account-wrapper a.header-login {
  border-right: 1px solid rgb(212, 212, 212);
}
.header-account-wrapper a.header-login:hover h6 {
  color: rgb(231, 112, 22);
  transition: all 0.9s ease 0s;
}
.header-account-wrapper a.header-create:hover h6 {
  color: rgb(231, 112, 22);
  transition: all 0.9s ease 0s;
}
.header-account-wrapper a.active-header-link h6 {
  color: rgb(231, 112, 22);
}
.header-account-wrapper a figure {
  float: left;
}
.header-account-wrapper a img {
  width: 30px;
  float: left;
}
.header-account-wrapper a h6 {
  color: rgb(26, 34, 58);
  float: left;
  padding: 9px 0px 0px;
  transition: all 0.9s ease 0s;
  text-transform: uppercase;
  font-size: 11px;
  font-family: gothambook, sans-serif;
}
.header-country {
  width: 10%;
  float: right;
  padding: 19px 0px 0px;
}
.flag-wrapper {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  float: left;
  border: 1px solid rgb(232, 232, 232);
}
.flag-wrapper img {
  width: 20px;
  height: 20px;
}
.dropdown-arrow {
  display: inline-block;
  width: 22px;
  height: 5px;
  float: right;
  padding: 9px 0px 0px;
}
.dropdown {
  position: relative;
  float: left;
  width: 100%;
}
.dropdown.expanded .dropdown-content {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s ease 0s, opacity 0.5s linear 0s;
}
.dropdown .dropbtn {
  cursor: pointer;
  font-size: 11px;
  border: none;
  font-family: gothambook, sans-serif;
  font-weight: 400;
  color: rgb(54, 54, 54);
  position: relative;
  background: transparent;
  outline: none;
  width: 100%;
  float: left;
}
.dropdown .header-dropbtn {
  width: auto;
  float: right;
}
.dropdown .slider-button {
  background-color: rgb(255, 255, 255);
  padding: 8px;
  margin-top: 0px;
}
.home-slider-dropdown {
  width: 70%;
  float: left;
}
.home-slider-form button {
  width: 30%;
  float: left;
  border: none;
  background-color: rgb(27, 34, 58);
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: all 0.9s ease 0s;
  padding: 13px;
  border-radius: 0px;
  font-size: 13px;
}
.home-slider-form button:hover {
  color: rgb(27, 34, 58);
  background-color: rgb(255, 255, 255);
  transition: all 0.9s ease 0s;
}
@media only screen and (max-width: 767px) {
  .dropdown .dropbtn {
    margin-top: 4px;
  }
}
.dropdown .dropbtn span.value {
  color: rgb(54, 54, 54);
  padding: 6px 5px 5px 20px;
  font-weight: 400;
  font-size: 12px;
  float: left;
}
.money-dropdown .dropbtn span.value {
  color: rgb(144, 144, 144);
}
.dropdown-content {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s ease 0s, opacity 0.5s linear 0s;
  position: absolute;
  background-color: rgb(249, 249, 249);
  color: rgb(0, 186, 76);
  min-width: 112px;
  z-index: 1;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
  left: 0px;
  top: 37px;
  height: 225px;
  overflow: auto;
}
.dropdown-content span.value {
  padding: 9px 5px 5px 20px;
  font-weight: 400;
  font-size: 12px;
  float: left;
}
.dropdown-content a {
  color: rgb(30, 30, 30);
  font-size: 15px;
  float: left;
  padding: 7px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  border-bottom: 1px solid rgba(221, 221, 221, 0.5);
  width: 100%;
}
.dropdown-content a::after {
  display: none;
}
.dropdown-content a:hover {
  background-color: rgb(221, 221, 221);
}
.dropdown-content a.active-country {
  background-color: rgb(221, 221, 221);
}
.mobile-header-wrapper {
  display: none;
  width: 100%;
  float: left;
}
.visible-mobile {
  width: 100%;
  float: left;
}
.mobile-logo-wrapper {
  width: 33.3333%;
  float: left;
  padding: 10px;
}
.mobile-logo-wrapper a {
}
.mobile-logo-wrapper img {
  float: left;
  width: 60px;
}
.mobile-cart {
  width: 33.3333%;
  float: left;
  padding: 10px;
  text-align: center;
}
.mobile-cart li {
  width: 100%;
  float: left;
  text-align: center;
}
.mobile-cart li a {
  float: left;
  position: relative;
  color: rgb(240, 90, 34);
  text-align: center;
}
.mobile-cart li a span {
  top: 0px;
  right: 0px;
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 4px 0px 0px;
}
.menu-icon {
  float: right;
  padding: 20px 0px 0px;
}
.drop-bars {
  float: right;
  padding: 0px 2%;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 2em !important;
}
#toggle {
  display: none;
}
#cross {
  display: none;
}
#toggle + label {
  display: inline-block;
  float: right;
  padding: 1px 15px;
  width: auto;
}
#toggle + label::before {
  display: none;
}
#toggle + label::after {
  display: none;
}
#toggle + label i {
  font-size: 2em;
  color: rgb(26, 34, 58);
}
#toggle:checked + label i#bars {
  display: none;
}
#toggle:checked + label i#cross {
  display: block;
}
#toggle:checked ~ #menu {
  height: 100vh;
}
.hidden-mobile {
  display: none;
  width: 100%;
  float: left;
  background-color: rgb(234, 234, 234);
}
.hidden-mobile ul {
  width: 100%;
  float: left;
}
.hidden-mobile ul li {
  width: 100%;
  float: left;
}
.hidden-mobile ul li a {
  width: 100%;
  float: left;
  padding: 15px;
  border-bottom: 1px solid rgb(183, 183, 183);
  font-size: 13px;
  text-transform: uppercase;
  color: rgb(33, 33, 33);
}
.hidden-mobile ul li a.active-mobile-header-link {
  background-color: rgb(10, 87, 48);
  color: rgb(255, 255, 255);
}
.hidden-mobile ul li ul.mobile-dropdown {
  width: 100%;
  float: left;
  padding: 5px 20px;
  background-color: rgb(221, 221, 221);
  display: none;
}
.hidden-mobile ul li ul.mobile-dropdown li {
  width: 100%;
  float: left;
}
.hidden-mobile ul li ul.mobile-dropdown li a {
  width: 100%;
  float: left;
}
.mobile-header-account {
  width: 100%;
  float: left;
  padding: 0px 0px;
  border-top: 1px dashed rgb(196, 196, 196);
}
.mobile-header-account-wrapper {
}
.mobile-header-account-wrapper a {
}
.mobile-header-country {
}
.mobile-header-country select {
}
:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.35s ease-in-out 0s;
}
.about-wrapper {
  width: 100%;
  float: left;
}
/* .about-us-banner {
  width: 100%;
  float: left;
  background-image: url("../images/home-slider-bg2.jpg");
  background-size: contain;
  background-repeat: repeat;
  background-position: center top;
  position: relative;
} */
.contact-banner {
  width: 100%;
  float: left;
  background-image: url("../images/home-slider-bg2.jpg");
  background-size: contain;
  background-repeat: repeat;
  background-position: center top;
  position: relative;
}
.conatct-us-banner {
  height: 100% !important;
}
.about-banner-caption {
  width: 40%;
  float: left;
  position: absolute;
  top: 120px;
  left: 60px;
}
.about-banner-caption h1 {
  color: rgb(255, 255, 255);
  font-size: 3em;
}
.about-banner-caption h1 span {
  color: rgb(27, 34, 58);
  font-family: gothamblack, sans-serif;
}
.about-banner-image {
  width: 70%;
  float: right;
}
.about-banner-image img {
  float: right;
}
.about-upesi-details {
  width: 100%;
  float: left;
  padding: 20px 0px;
  position: relative;
}
.float-element-left {
  position: absolute;
  top: 50%;
  left: 3em;
  display: none;
}
.float-element-right {
  position: absolute;
  top: 50%;
  right: 3em;
  display: none;
}
.about-upesi-header {
  width: 100%;
  float: left;
  text-align: center;
  padding: 30px 10px;
}
.about-upesi-header h3 {
  background-image: url("../images/header-bg.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center center;
  width: 700px;
  margin: auto;
  font-size: 15px;
}
.about-upesi-header h3 span {
  text-transform: uppercase;
  color: rgb(26, 34, 58);
  background-color: rgb(255, 255, 255);
  padding: 0px 11px;
}
.about-details-columns {
  width: 100%;
  float: left;
  padding: 0px 15em;
}
.about-details-column {
  width: 50%;
  float: left;
  padding: 20px;
}
.about-details-column p {
  color: rgb(122, 122, 122);
  padding: 15px 0px;
}
.about-details-column1 {
}
.about-details-column2 {
}
.about-mission-vision {
  width: 100%;
  float: left;
  padding: 30px 15em;
}
.mission-vision-column {
  width: 48%;
  float: left;
  margin: 1%;
  padding: 30px;
  text-align: center;
  height: 200px;
}
.mission-vision-column h4 {
  color: rgb(26, 34, 58);
  text-transform: uppercase;
}
.mission-vision-column p {
  color: rgb(122, 122, 122);
  padding: 10px 0px;
}
.about-mission {
  background-color: rgb(253, 229, 212);
  border-radius: 6px;
}
.about-vision {
  background-color: rgb(242, 246, 255);
  border-radius: 6px;
}
.about-us-video {
  width: 100%;
  float: left;
  position: relative;
}
.about-us-video a {
}
.about-us-video a img {
  filter: grayscale(100%);
  transition: all 0.9s ease 0s;
}
.video-play-control {
  width: 100%;
  position: absolute;
  right: 0px;
  left: 0px;
  top: 40%;
  text-align: center;
}
.video-play-control i {
  color: rgb(255, 255, 255);
  background-color: rgb(231, 112, 22);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 18px 0px 0px 3px;
  box-shadow: rgb(111, 111, 111) 1px 3px 7px 2px;
  transition: all 0.9s ease 0s;
}
.video-play-control i:hover {
  color: rgb(231, 112, 22);
  background-color: rgb(255, 255, 255);
  transition: all 0.9s ease 0s;
}
.about-us-video a img:hover {
  filter: grayscale(0%);
  transition: all 0.9s ease 0s;
}
.video-play-control p {
  color: rgb(255, 255, 255);
  font-family: gothambold, sans-serif;
  text-transform: uppercase;
  font-size: 11px;
}
.video-pattern {
}
.video-pattern img {
}
.about-networks {
  width: 100%;
  float: left;
  padding: 30px 10em;
  position: relative;
}
.network-map {
  width: 100%;
  float: left;
}
.network-map img {
  width: 60%;
}
.network-map-container {
  width: 60%;
  margin: 0px auto;
  float: left;
}
ul#continents3 {
  list-style: none;
  width: 100%;
  height: 588px;
  position: relative;
  background: url("../images/network-map.png") center center no-repeat;
  margin: auto;
}
ul#continents3 li {
  position: absolute;
}
ul#continents3 li.northamerica:hover {
  background-position: 0px -270px;
}
ul#continents3 li.northamerica:hover span {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s ease 0s, opacity 0.9s linear 0s;
}
ul#continents3 li.southamerica:hover {
  background-position: -226px -273px;
}
ul#continents3 li.southamerica:hover span {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s ease 0s, opacity 0.9s linear 0s;
}
ul#continents3 li.africa:hover {
  background-position: -209px -417px;
}
ul#continents3 li.africa:hover span {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s ease 0s, opacity 0.9s linear 0s;
}
ul#continents3 li.europe:hover {
  background-position: -22px -427px;
}
ul#continents3 li.europe:hover span {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s ease 0s, opacity 0.9s linear 0s;
}
ul#continents3 li.asia:hover {
  background-position: -363px -268px;
}
ul#continents3 li.asia:hover span {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s ease 0s, opacity 0.9s linear 0s;
}
ul#continents3 li.australia:hover {
  background-position: -412px -455px;
}
ul#continents3 li.australia:hover span {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s ease 0s, opacity 0.9s linear 0s;
}
.northamerica {
  width: 230px;
  height: 300px;
  top: 30px;
  left: 390px;
}
.southamerica {
  width: 120px;
  height: 190px;
  top: 380px;
  left: 580px;
}
.africa {
  width: 170px;
  height: 180px;
  bottom: 83px;
  left: 737px;
}
.europe {
  width: 150px;
  height: 235px;
  top: 1px;
  left: 621px;
}
.asia {
  width: 485px;
  height: 250px;
  top: 70px;
  right: 340px;
}
.australia {
  width: 234px;
  height: 200px;
  bottom: 3px;
  right: 448px;
}
ul#continents3 li span {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s ease 0s, opacity 0.9s linear 0s;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 30px;
  height: 100%;
  width: 350px;
  box-shadow: rgb(156, 156, 156) 1px 1px 7px 2px;
  text-align: center;
  float: left;
}
ul#continents3 li span h4 {
  color: rgb(26, 34, 58);
}
ul#continents3 li span p {
  color: rgba(26, 34, 58, 0.7);
  padding: 15px 0px;
}
ul#continents3 li:hover span {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s ease 0s, opacity 0.9s linear 0s;
}
.about-testimonials {
  width: 100%;
  float: left;
  background-image: url("../images/testimonials-bg.jpg");
  background-repeat: repeat;
  background-size: contain;
  background-position: center top;
  position: relative;
  padding: 30px 10em;
  display: table;
}
.testimonial-table {
  width: 40%;
  display: table-cell;
  vertical-align: middle;
}
.testimonials-header {
  width: 100%;
  float: left;
}
.testimonials-header h2 {
  color: rgb(26, 34, 58);
  font-size: 2em;
}
.testimonials-header p {
  color: rgb(102, 102, 102);
  padding: 20px 0px;
}
.testimonials-header p span {
  font-family: gothamblack, sans-serif;
}
.testimony-content-bg {
}
.testimony-content figure {
}
.testimony-content figure img {
  width: 64px;
}
.testimony-content p {
  padding: 40px 0px;
  color: rgb(202, 215, 238);
}
.testimony-content h4 {
  padding: 4px 0px;
  color: rgb(202, 215, 238);
}
.testimony-content h6 {
  color: rgb(202, 215, 238);
}
.wrapper {
  position: relative;
  margin: 0px auto;
  width: 60%;
  overflow: hidden;
  display: table-cell;
  vertical-align: top;
}
.testimony-content-bg {
  position: absolute;
  left: 100%;
  color: rgb(255, 255, 255);
  top: 0px;
  opacity: 0;
  transition: left 1.8s ease 0s, opacity 0.5s ease 0s;
  width: 500px;
  height: 500px;
  background-color: rgb(27, 34, 58);
  border: 20px solid rgb(255, 255, 255);
  border-radius: 50%;
  padding: 80px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
}
.testimony-content-bg.activeText {
  position: relative;
  left: 0px;
  opacity: 1;
}
.testimony-content-bg.slideLeft {
  left: -100%;
  opacity: 0.1;
}
.testimony-content-bg::before {
  font-size: 3em;
  line-height: 0.1em;
  margin-right: 0.1em;
  vertical-align: -0.4em;
}
.testimony-content-bg::after {
  font-size: 3em;
  line-height: 0.1em;
  margin-left: 0.1em;
  vertical-align: -0.45em;
}
.dots-wrap {
  display: none;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background: rgb(127, 140, 141);
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.arrows {
  color: rgba(127, 140, 141, 0.62);
  width: 30px;
  height: 30px;
  position: absolute;
  top: 75%;
  padding: 5px 1px 1px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}
.prev {
  left: 10em;
  background-color: rgb(202, 215, 238);
  color: rgb(27, 34, 58);
}
.next {
  left: 12.5em;
  background-color: rgb(216, 127, 47);
  color: rgb(255, 255, 255);
}
.prev:active,
.next:active {
  color: white;
}
.trusted-partners-wrapper {
  width: 100%;
  float: left;
  padding: 0px 8em 30px;
}
.network-key {
  width: 40%;
  float: right;
  padding: 40px 0px 20px;
}
.network-key h3 {
  color: rgb(25, 79, 144);
  text-transform: uppercase;
  font-size: 14px;
}
.solutions-accordion {
  width: 100%;
  float: left;
}
.solutions-accordion li {
  width: 100%;
  float: left;
  background-color: rgb(255, 255, 255);
  margin: 20px 0px 0px;
  border: 1px solid rgb(208, 208, 208);
}
.solutions-accordion li h6 {
  float: left;
  padding: 0px;
  color: rgb(96, 96, 96);
}
.solutions-accordion .link {
  cursor: pointer;
  display: block;
  padding: 15px;
  color: rgb(96, 96, 96);
  font-size: 14px;
  font-weight: 700;
  position: relative;
  transition: all 0.4s ease 0s;
  float: left;
  width: 100%;
  font-family: latoregular, sans-serif;
}
.solutions-accordion li:last-child .link {
  border-bottom: 0px;
}
.solutions-accordion li i {
  position: absolute;
  top: 16px;
  right: 12px;
  font-size: 14px;
  color: rgb(101, 69, 5);
  transition: all 0.4s ease 0s;
}
.solutions-accordion li i.fa-angle-down {
}
.solutions-accordion li.open .link {
  font-weight: 900;
  transition: all 0.4s ease 0s;
}
.solutions-accordion li.open i {
  color: rgb(250, 0, 0);
}
.solutions-accordion li.open i.fa-angle-down {
  transform: rotate(180deg);
}
.solutions-accordion figure {
  display: inline-block;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
}
.solutions-accordion img {
  width: 50px;
  height: 50px;
}
.submenu {
  display: none;
  font-size: 14px;
  padding: 1% 15px;
  float: left;
  width: 100%;
  position: relative;
  border-top: 1px solid rgb(208, 208, 208);
}
.submenu p {
  color: rgb(37, 37, 38);
  line-height: 1.7em;
}
.submenu li {
  font-family: latoregular, sans-serif;
  border: none !important;
  color: rgb(0, 35, 102) !important;
  line-height: 22px !important;
}
.submenu a {
  display: block;
  text-decoration: none;
  color: rgb(217, 217, 217);
  padding: 12px 12px 12px 42px;
  transition: all 0.25s ease 0s;
}
.submenu a:hover {
  background: rgb(182, 59, 77);
  color: rgb(255, 255, 255);
}
.partners-slider {
  width: 100%;
  float: left;
}
.item {
  border: 1px solid rgb(237, 237, 237);
  border-radius: 5px;
  padding: 20px 0px;
}
.blog-wrapper {
  width: 100%;
  float: left;
}
/* .media-top-search {
  width: 100%;
  float: left;
  background-image: url("../images/blog-bg.jpg");
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 70px 30px 30px;
  height: 500px;
} */
.media-header {
  width: 100%;
  float: left;
}
.media-header h3 {
  color: rgb(255, 255, 255);
  background-image: url("../images/media-header-bg.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center center;
  width: 650px;
  margin: auto;
  font-size: 15px;
}
.media-header h3 span {
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  background-color: rgba(17, 28, 56, 0);
  padding: 0px 11px;
}
/* .money-top-search {
  width: 100%;
  float: left;
  background-image: url("../images/privacy-bg.png");
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  padding: 70px 30px 30px;
  height: 200px;
} */
.money-header {
  width: 100%;
  float: left;
}
.money-header h3 {
  color: rgb(255, 255, 255);
  background-image: url("../images/media-header-bg.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center center;
  width: 650px;
  margin: auto;
  font-size: 15px;
}
.money-header h3 span {
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  background-color: rgb(17, 28, 56);
  padding: 0px 11px;
}
.media-search-form {
  width: 100%;
  float: left;
  padding: 40px 0px 20px;
  display: none;
}
.media-search-form form {
}
.media-search-form form input {
}
.media-search-form form input[type="search"] {
  width: 40%;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(201, 216, 253, 0.33);
  border-radius: 6px;
  padding: 13px;
  color: rgb(199, 214, 253);
}
.media-search-form form input[type="search"]::placeholder {
  color: rgb(199, 214, 253);
}
.media-search-form form input[type="submit"] {
  border: none;
}
.media-suggestions {
  width: 100%;
  float: left;
}
.media-suggestions ul {
  width: 100%;
  float: left;
  padding: 20px;
}
.media-suggestions ul li {
  display: inline-block;
}
.media-suggestions ul li a.suggestion-title {
  color: rgb(216, 127, 45);
  float: left;
}
.media-suggestions ul li i {
  color: rgb(216, 127, 45);
  float: right;
  padding: 0px 0px 0px 10px;
}
.media-suggestions ul li a {
  color: rgb(199, 214, 253);
  font-size: 13px;
  padding: 14px;
  float: left;
  margin: 0px;
}
.media-suggestions ul li a:hover {
  color: rgb(216, 127, 45);
}
.media-lists {
  width: 100%;
  float: left;
  margin: -300px 0px 0px;
  z-index: 9999999;
  padding: 0px 6em;
}
.media-lists ul {
}
.media-lists ul li {
}
.media-lists ul li a {
}
.media-image {
  width: 100%;
  float: left;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  opacity: 1;
}
.media-image img {
  width: 100%;
  transition: transform 0.2s ease 0s;
}
.media-caption {
  width: 100%;
  float: left;
  background-color: rgb(241, 245, 254);
  padding: 30px;
  position: relative;
  transition: all 0.9s ease 0s;
}
.media-caption::after {
  bottom: 100%;
  left: 20%;
  border-style: solid;
  border-image: initial;
  content: "";
  height: 0px;
  width: 0px;
  position: absolute;
  pointer-events: none;
  border-color: rgba(241, 245, 254, 0) rgba(241, 245, 254, 0) rgb(241, 245, 254);
  border-width: 15px;
  margin-left: -15px;
  display: none;
}
.media-caption span {
  color: rgb(54, 54, 54);
  font-size: 10px;
  transition: all 0.9s ease 0s;
}
.media-caption h3 {
  color: rgb(54, 54, 54);
  padding: 20px 0px;
  width: 100%;
  float: left;
  line-height: 22px;
  transition: all 0.9s ease 0s;
}
.media-caption b {
  float: left;
  width: 30px;
  height: 1px;
  background-color: rgb(54, 54, 54);
  transition: all 0.9s ease 0s;
}
.media-caption p {
  width: 100%;
  float: left;
  color: rgb(122, 122, 122);
  padding: 20px 0px;
  transition: all 0.9s ease 0s;
}
.media-caption h5 {
  width: 100%;
  float: left;
  color: rgb(122, 122, 122);
  font-size: 10px;
  text-transform: uppercase;
  transition: all 0.9s ease 0s;
}
.media-caption h5 i {
  padding: 0px 0px 0px 15px;
  transition: all 0.9s ease 0s;
}
.media-lists ul li a:hover img {
  transform: scale(1.5);
  opacity: 0.4;
}
.media-lists ul li a:hover .media-caption {
  background-color: rgb(242, 128, 32);
  transition: all 0.9s ease 0s;
}
.media-lists ul li a:hover .media-caption span {
  color: rgb(27, 34, 58);
  transition: all 0.9s ease 0s;
}
.media-lists ul li a:hover .media-caption h3 {
  color: rgb(27, 34, 58);
  transition: all 0.9s ease 0s;
}
.media-lists ul li a:hover .media-caption b {
  background-color: rgb(27, 34, 58);
  transition: all 0.9s ease 0s;
}
.media-lists ul li a:hover .media-caption p {
  color: rgb(255, 255, 255);
  transition: all 0.9s ease 0s;
}
.media-lists ul li a:hover .media-caption h5 {
  color: rgb(27, 34, 58);
  transition: all 0.9s ease 0s;
}
.media-lists ul li a:hover .media-caption h5 i {
  color: rgb(27, 34, 58);
  transition: all 0.9s ease 0s;
}
.create-account-wrapper {
  width: 100%;
  float: left;
  height: 100%;
}
/* .create-account-form {
  height: 100%;
  width: 100%;
  float: left;
  background-image: url("../images/preloader-bg.jpg");
  text-align: center;
  background-position: 100%;
  padding: 30px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  background-size: cover;
  background-repeat: no-repeat;
} */

.height-login-hash {
  height: calc(100vh - 18vh);
}
.login-wrapper {
  height: calc(100vh - 136px) !important;
}
/* .forex-form {
  width: 100%;
  float: left;
  background-image: url("../images/preloader-bg.jpg");
  text-align: center;
  background-position: center top;
  padding: 30px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  background-size: cover;
  background-repeat: no-repeat;
} */
.create-account-content {
  width: 40%;
  margin: auto;
}
/* .create-account-form form {
  width: 100%;
  padding: 0px 50px;
  margin: auto;
  float: left;
} */
/* .create-account-form form p {
  color: rgb(199, 214, 253);
  text-align: left;
} */
/* .create-account-form form p a {
  color: rgb(255, 255, 255);
  font-size: 13px;
}
.create-account-form form p a:hover {
  color: rgb(216, 127, 45);
}
.create-account-form form select {
}
.create-account-form form label {
  width: 100%;
  float: left;
  text-align: left;
  color: rgb(199, 214, 253);
  padding: 30px 0px 10px;
  font-family: gothambook, sans-serif;
  font-size: 12px;
}
.create-account-form form input {
  margin: 15px 0px 0;
}
.create-account-form form .nice-select {
  margin: 15px 0px 0;
  border: 1px solid rgb(255, 255, 255, 0.3) !important;
}
.create-account-form h4 {
  color: rgb(199, 214, 253);
  text-align: left;
}
.create-account-form p {
  padding: 20px 0px 20px;
  color: rgb(199, 214, 253);
} */
/* .forex-form form {
  width: 100%;
  padding: 0px 50px;
  margin: auto;
  float: left;
}
.forex-form form p {
  color: rgb(199, 214, 253);
  text-align: left;
}
.forex-form form p a {
  color: rgb(255, 255, 255);
  font-size: 13px;
}
.forex-form form p a:hover {
  color: rgb(216, 127, 45);
}
.forex-form form select {
}
.forex-form form label {
  width: 100%;
  float: left;
  text-align: left;
  color: rgb(199, 214, 253);
  padding: 30px 0px 10px;
  font-family: gothambook, sans-serif;
  font-size: 12px;
}
.forex-form form input {
  margin: 15px 0px;
}
.forex-form h4 {
  color: rgb(199, 214, 253);
  text-align: left;
}
.forex-form p {
  padding: 20px 0px 20px;
  color: rgb(199, 214, 253);
} */
.create-acc {
}
.create-acc a {
  background-color: rgb(199, 214, 253);
  border: none;
  color: rgb(26, 34, 58);
  padding: 10px 50px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.9s ease 0s;
  font-size: 12px;
  width: 100%;
  float: left;
  text-transform: uppercase;
}
.create-acc a:hover {
  color: rgb(199, 214, 253);
  background-color: rgb(26, 34, 58);
}
.email-input {
}
.email-input input {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(201, 216, 253, 0.33);
  border-radius: 6px;
  padding: 13px;
  color: black;
  font-size: 12px;
}
.email-input input::placeholder {
  color: black;
  font-size: 12px;
}
.password-input {
}
.password-input input::placeholder {
  color: black;
  font-size: 12px;
}
.password-input input {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(201, 216, 253, 0.33);
  border-radius: 6px;
  padding: 13px;
  color: black;
}
.password-input span {
  display: none;
}
.submit-input {
}
.submit-input input {
  background-color: rgb(231, 112, 22);
  border: none;
  color: rgb(255, 255, 255);
  padding: 13px 10px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.9s ease 0s;
  width: 100%;
  text-transform: uppercase;
  font-size: 13px;
}
.submit-input input:hover {
  color: rgb(216, 127, 45);
  background-color: rgb(255, 255, 255);
  transition: all 0.9s ease 0s;
}
.contact-background {
  width: 100%;
  float: left;
  background-image: url("../images/contact-bg.jpg");
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.contact-content {
  width: 70%;
  margin: auto;
  padding: 3em;
}
.contact-header {
  width: 100%;
  float: left;
}
.contact-header h3 {
  color: rgb(255, 255, 255);
  background-image: url("../images/media-header-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 650px;
  margin: auto;
  font-size: 15px;
}
.contact-header h3 span {
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  background-color: rgb(17, 28, 56);
}
.contact-header p {
  color: rgb(199, 214, 253);
  padding: 10px 0px;
}
.contact-information {
  width: 100%;
  float: left;
  padding: 3em 0px;
}
.contact-information ul {
  width: 100%;
  float: left;
  display: table;
}
.contact-information ul li {
  width: 33.3333%;
  display: table-cell;
  border-right: 1px solid rgba(199, 214, 253, 0.37);
  text-align: center;
  padding: 20px;
}
.contact-information ul li:last-child {
  border-right: none;
}
.contact-information ul li figure {
  margin: auto;
  width: 100%;
  float: left;
}
.contact-information ul li figure img {
  width: 24px;
}
.contact-information ul li h4 {
  color: rgb(199, 214, 253);
  text-transform: capitalize;
  width: 100%;
  float: left;
  padding: 15px 0px;
  font-size: 14px;
}
.contact-information ul li p {
  color: rgb(255, 255, 255);
  padding: 10px 0px;
  font-size: 12px;
}
.contact-information ul li a {
  color: rgb(255, 255, 255);
  padding: 10px 0px;
  font-size: 12px;
  font-family: gothambook, sans-serif;
}
.contact-information ul li a:hover {
  color: rgb(199, 214, 253);
}
.contact-form {
  width: 100%;
  float: left;
}
.contact-form form {
  width: 100%;
  float: left;
}
.half-input {
  width: 50%;
  float: left;
}
.third-input {
  width: 33.333%;
  float: left;
}
.contact-input-style {
  padding: 20px;
}
.contact-input-style input {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(201, 216, 253, 0.33);
  border-radius: 6px;
  padding: 13px;
  color: rgb(199, 214, 253);
  font-size: 12px;
  margin: 0px 0px 30px;
  width: 100%;
  float: left;
}
.contact-input-style input::placeholder {
  font-size: 12px;
  color: rgb(199, 214, 253);
}
.contact-input-style textarea {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(201, 216, 253, 0.33);
  border-radius: 6px;
  padding: 13px;
  color: rgb(199, 214, 253);
  font-size: 12px;
  resize: none;
  width: 100%;
  float: left;
  height: 253px;
}
.contact-input-style textarea::placeholder {
  font-size: 12px;
  color: rgb(199, 214, 253);
}
.select-service {
  width: 100%;
  float: left;
  text-align: center;
  padding: 1em 0px;
}
.select-service p {
  color: rgb(216, 127, 45);
  padding: 5px 15px 0px 0px;
}
.select-service p i {
  color: rgb(216, 127, 45);
  padding: 0px 0px 0px 10px;
}
.switch-field {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.switch-field li {
  float: left;
}
.switch-field input {
  position: absolute;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  border: 0px;
  overflow: hidden;
}
.switch-field label {
  background-color: transparent;
  color: rgb(199, 214, 253);
  line-height: 1;
  text-align: center;
  padding: 10px 20px;
  border: 1px solid transparent;
  transition: all 0.1s ease-in-out 0s;
  border-radius: 4px;
  font-family: gothammedium, sans-serif;
  font-size: 12px;
  float: left;
}
.switch-field label:hover {
  cursor: pointer;
}
.switch-field input:checked + label {
  background-color: transparent;
  box-shadow: none;
  color: rgb(216, 127, 45);
  border: 1px solid rgb(216, 127, 45);
}
.switch-field label:first-of-type {
}
.switch-field label:last-of-type {
}
.contact-submit {
  width: 100%;
  float: left;
  padding: 2em 0px;
}
.contact-submit button {
  background-color: rgb(216, 127, 45);
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  transition: all 0.9s ease 0s;
  border: none;
  border-radius: 6px;
  text-align: center;
  padding: 14px 20px;
  width: 35%;
  cursor: pointer;
  font-size: 12px;
}
.contact-submit button:hover {
  color: rgb(216, 127, 45);
  background-color: rgb(255, 255, 255);
  transition: all 0.9s ease 0s;
}
.best-choice-wrapper {
  width: 100%;
  float: left;
  padding: 0px;
}
.best-choice-lists {
  width: 100%;
  float: left;
}
.best-choice-lists ul {
  width: 100%;
  float: left;
}
.best-choice-lists ul li {
  width: 25%;
  float: left;
  display: inline-block;
  background-color: rgb(249, 249, 249);
  border-right: 1px solid rgb(231, 231, 231);
  text-align: center;
  transition: all 0.9s ease 0s;
  padding: 20px;
}
.best-choice-lists ul li a {
  width: 100%;
  float: left;
  padding: 10px;
}
.best-choice-lists ul li img {
  width: 73px;
}
.best-choice-lists ul li h4 {
  color: rgb(102, 102, 102);
  padding: 20px 0px 5px;
  transition: all 1s ease 0s;
}
.best-choice-lists ul li h5 {
  color: rgb(47, 79, 132);
  padding: 20px 0px 5px;
  transition: all 1s ease 0s;
}
.best-choice-lists ul li p {
  color: rgb(102, 102, 102);
  transition: all 1s ease 0s;
}
.best-choice-lists ul li:hover {
  background-color: rgb(238, 210, 185);
  transition: all 0.9s ease 0s;
}
.best-choice-lists ul li:hover img {
  transition: transform 300ms ease 0s;
  transform: translate3d(0px, 100%, 0px);
}
.best-choice-lists ul li:hover h4 {
  transition: transform 300ms ease 0s;
  transform: translate3d(0px, -190%, 0px);
}
.best-choice-lists ul li:hover h5 {
  transition: transform 300ms ease 0s;
  transform: translate3d(0px, -190%, 0px);
}
.best-choice-lists ul li:hover p {
  transition: transform 300ms ease 0s;
  transform: translate3d(0px, -310%, 0px);
}
.contact-select-service {
  padding: 0px 0px 20px;
  width: 100%;
  float: left;
}
.blog-pagination {
  display: inline-block;
  position: relative;
  width: 100%;
  float: left;
  padding: 3em 0px;
  text-align: center;
}
.index {
  cursor: pointer;
  display: inline;
  margin-right: 30px;
  padding: 5px;
  user-select: none;
}
.index:last-child {
  margin: 0px;
}
.blog-pagination svg {
  left: -200px;
  position: absolute;
  top: 30px;
  transition: transform 500ms ease 0s;
  width: 46px;
  right: 0px;
  margin: auto;
}
.blog-pagination path {
  fill: none;
  stroke: rgb(242, 128, 32);
  stroke-dasharray: 150, 150;
  stroke-width: 15;
}
.blog-pagination.open:not(.flip) path {
  animation: 500ms ease 0s 1 normal none running OpenRight;
}
.blog-pagination.open.flip path {
  animation: 500ms ease 0s 1 normal none running OpenLeft;
}
.blog-pagination.i1 svg {
  transform: translateX(0px);
}
.blog-pagination.i2 svg {
  transform: translateX(48px);
}
.blog-pagination.i3 svg {
  transform: translateX(98px);
}
.blog-pagination.i4 svg {
  transform: translateX(149px);
}
.blog-pagination.i5 svg {
  transform: translateX(199px);
}
@keyframes OpenRight {
  25% {
    stroke-dasharray: 100, 150;
  }
  60% {
    stroke-dasharray: 100, 150;
  }
  100% {
    stroke-dasharray: 150, 150;
  }
}
@keyframes OpenLeft {
  25% {
    stroke-dashoffset: -50px;
  }
  60% {
    stroke-dashoffset: -50px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.faqs-wrapper {
  width: 100%;
  float: left;
}
.faqs-banner {
  width: 100%;
  float: left;
  background-image: url("../images/faqs-banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding: 60px;
  text-align: center;
}
/* .faqs-header {
  width: 100%;
  float: left;
} */
/* .faqs-header h3 {
  color: rgb(255, 255, 255);
  background-image: url("../images/faqs-header-bg.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center center;
  width: 650px;
  margin: auto;
  font-size: 15px;
}
.faqs-header h3 span {
  color: rgb(49, 26, 0);
  text-transform: uppercase;
  background-color: rgb(242, 128, 32);
  padding: 0px 11px;
} */
.faqs-banner p {
  color: rgb(49, 26, 0);
  width: 100%;
  float: left;
  padding: 7px 0px 60px;
}
.privacy-banner {
  width: 100%;
  float: left;
  background-image: url("../images/faqs-banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding: 60px;
  text-align: center;
}
.terms-banner {
  width: 100%;
  float: left;
  background-image: url("../images/privacy-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding: 60px;
  text-align: center;
}
.privacy-header {
  width: 100%;
  float: left;
}
.privacy-header h3 {
  color: rgb(255, 255, 255);
  background-image: url("../images/media-header-bg.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center center;
  width: 650px;
  margin: auto;
  font-size: 15px;
}
.privacy-header h3 span {
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  padding: 0px 11px;
  font-size: 1rem;
}
.privacy-banner p {
  color: rgb(255, 255, 255);
  width: 100%;
  float: left;
  padding: 7px 0px 0px;
}
.terms-banner p {
  color: rgb(255, 255, 255);
  width: 100%;
  float: left;
  padding: 7px 0px 0px;
}
.faqs-search-form {
  width: 100%;
  float: left;
  display: none;
}
.faqs-search-form form {
  width: 100%;
  float: left;
}
.faqs-search-form form input {
  width: 40%;
  background-color: rgba(148, 73, 0, 0.2);
  border: 1px solid rgba(49, 26, 0, 0.22);
  border-radius: 6px;
  padding: 13px;
  color: rgb(49, 26, 0);
}
.faqs-search-form form input[type="search"] {
  color: rgb(49, 26, 0);
}
.faqs-search-form form input[type="search"]::placeholder {
  color: rgb(49, 26, 0);
}
.faqs-search-form form button[type="submit"] {
  transition: all 0.9s ease 0s;
  cursor: pointer;
  margin: 0px 0px 0px -50px;
  border: none;
  background-color: transparent;
  color: rgb(49, 26, 0);
  padding: 0px;
}
.faqs-search-form form button[type="submit"]:hover {
  transition: all 0.9s ease 0s;
  color: rgb(222, 136, 44);
}
.media-search-form form button[type="submit"] {
  transition: all 0.9s ease 0s;
  cursor: pointer;
  margin: 0px 0px 0px -50px;
  border: none;
  background-color: transparent;
  color: rgb(199, 214, 253);
  padding: 0px;
}
.media-search-form form button[type="submit"]:hover {
  transition: all 0.9s ease 0s;
  color: rgb(222, 136, 44);
}
.faqs-lists-details {
  width: 100%;
  float: left;
}
.faqs-lists {
  width: 30%;
  float: left;
  padding: 30px;
}
.faqs-lists h4 {
  width: 100%;
  float: left;
  padding: 20px 0px;
  color: rgb(26, 34, 58);
}
.faqs-lists ul {
  width: 100%;
  float: left;
}
.faqs-lists ul li {
  width: 100%;
  float: left;
}
.faqs-lists ul li a {
  width: 100%;
  float: left;
  padding: 12px;
  color: rgb(110, 110, 111);
  font-size: 13px;
  border-bottom: 1px solid rgb(236, 236, 236);
  font-family: gothambook, sans-serif;
}
.faqs-lists ul li a:hover {
  color: rgb(26, 34, 58);
  border-bottom: 1px solid rgb(26, 34, 58);
}
.faqs-lists ul li a.active-faqs-link {
  color: rgb(26, 34, 58);
  border-bottom: 1px solid rgb(26, 34, 58);
}
.faqs-details {
  width: 70%;
  float: left;
  padding: 70px;
}
.faqs-details h4 {
  color: rgb(26, 34, 58);
  padding: 20px 0px 0px;
  width: 100%;
  float: left;
  text-transform: capitalize;
}
.faqs-details p {
  width: 100%;
  float: left;
  padding: 10px 0px;
  color: rgb(122, 122, 122);
}
.faqs-details p a {
  color: rgb(242, 128, 32);
}
.faqs-details p a:hover {
  color: rgb(26, 34, 58);
}
.faqs-details iframe {
  width: 100%;
  height: 500px;
  float: left;
  display: block;
  margin: 20px 0px;
}
.faqs-details img {
}
.faqs-details ul {
  width: 100%;
  float: left;
  display: list-item;
}
.faqs-details ul li {
  font-family: gothambook, sans-serif;
  color: rgb(110, 110, 111);
  font-size: 13px;
  line-height: 22px;
  list-style: inside;
}
.cant-find-answers {
  width: 100%;
  float: left;
  background-image: url("../images/privacy-bg.png");
  text-align: center;
  background-position: center center;
  padding: 40px;
}
.auto-answers {
  width: 50%;
  margin: auto;
}
.faqs-social {
  width: 100%;
  float: left;
}
.faqs-social p {
  color: rgb(199, 214, 253);
  width: 100%;
  float: left;
  padding: 10px 0px 0px;
}
.form-social {
  width: 100%;
  float: left;
  padding: 30px 0px;
}
.form-social form {
  width: 60%;
  float: left;
}
.form-social input {
}
.form-social input[type="email"] {
  width: 90%;
  background-color: rgb(199, 214, 253);
  border: 1px solid rgba(201, 216, 253, 0.33);
  border-radius: 6px;
  padding: 13px;
  color: rgb(26, 34, 58);
}
.form-social input[type="email"]::placeholder {
  color: rgb(27, 34, 58);
}
.form-social button[type="submit"] {
  transition: all 0.9s ease 0s;
  cursor: pointer;
  margin: 0px 0px 0px -50px;
  border: none;
  background-color: transparent;
  color: rgb(27, 34, 58);
  padding: 0px;
}
.form-social button[type="submit"]:hover {
  transition: all 0.9s ease 0s;
  color: rgb(255, 255, 255);
}
.faqs-social-lists {
  width: 40%;
  float: left;
  text-align: center;
}
.faqs-social-lists li {
  display: inline-block;
  padding: 1px;
}
.faqs-social-lists li a {
  width: 30px;
  height: 30px;
  background-color: rgb(199, 214, 253);
  border: 1px solid rgb(199, 214, 253);
  border-radius: 50%;
  text-align: center;
  padding: 7px 0px 0px;
  color: rgb(27, 34, 58);
}
.currency-result {
  width: 100%;
  float: left;
  padding: 4em 0px;
  text-align: center;
}
.currency-result h1 {
  color: rgb(216, 127, 46);
  font-size: 3em;
}
.currency-result h1 sub {
  font-size: 11px;
}
.currency-result h5 {
  color: rgb(199, 214, 253);
  font-size: 12px;
  padding: 5px 0px;
}
.currency-result ol {
  width: 100%;
  float: left;
  padding: 20px 0px;
}
.currency-result ol li {
  color: rgb(199, 214, 253);
  font-size: 12px;
  font-family: gothambold, sans-serif;
  display: inline-block;
}
.currency-result ol li:nth-child(2n + 1) {
  text-align: left;
  float: left;
}
.currency-result ol li:nth-child(2n) {
  text-align: right;
  float: right;
}
.forex-ticker-wrapper {
  width: 100%;
  float: left;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  padding: 10px 10px 0px;
}
.faqs-social-lists li a:hover {
  background-color: transparent;
  color: rgb(199, 214, 253);
}
.home-wrapper {
  flex: 1;
  width: 100%;
  float: left;
}
/* .home-banner-slider {
  width: 100%;
  float: left;
  position: relative;
}
.home-banner-slider ul {
}
.home-banner-slider ul li {
}
.home-banner-slider ul li:nth-child(2n + 1) {
  background-image: url("../images/home-slider-bg1.jpg");
}
.home-banner-slider ul li:nth-child(2n) {
  background-image: url("../images/home-slider-bg2.jpg");
} */
.slider-copy {
  position: absolute;
  top: 0px;
  left: 90px;
  bottom: 0px;
  width: 40%;
}
.display-caption {
  height: 100%;
  display: table;
  width: 100%;
}
.slider-vertical {
  display: table-cell;
  vertical-align: middle;
}
.slider-copy h1 {
  font-size: 3em;
  padding: 20px;
  text-transform: capitalize;
  font-family: gothamblack, sans-serif;
  color: rgb(255, 255, 255);
  font-style: italic;
  font-weight: 900;
}
.slider-copy h1 span {
  letter-spacing: 0px;
  font-family: gothamblack, sans-serif;
  font-style: italic;
  font-weight: 900;
  color: rgb(255, 255, 255);
}
.slider-copy form {
  width: 100%;
  float: left;
}
.slider-copy form select {
}
/* .home-banner-slider ul li:nth-child(2n + 1) h1 {
  color: rgb(255, 255, 255);
}
.home-banner-slider ul li:nth-child(2n + 1) h1 span {
  color: rgb(27, 34, 58);
}
.home-banner-slider ul li:nth-child(2n + 1) a {
  background-color: rgb(27, 34, 58);
  color: rgb(199, 214, 253);
}
.home-banner-slider ul li:nth-child(2n + 1) a:hover {
  color: rgb(27, 34, 58);
  background-color: rgb(199, 214, 253);
}
.home-banner-slider ul li:nth-child(2n) h1 {
  color: rgb(255, 255, 255);
}
.home-banner-slider ul li:nth-child(2n) h1 span {
  color: rgb(199, 214, 253);
}
.home-banner-slider ul li:nth-child(2n) a {
  background-color: rgb(216, 127, 45);
  color: rgb(255, 255, 255);
}
.home-banner-slider ul li:nth-child(2n) a:hover {
  color: rgb(216, 127, 45);
  background-color: rgb(255, 255, 255);
} */
.slider-image {
  width: 60%;
  float: right;
}
.slider-image img {
}
.share-video {
  position: absolute;
  top: 20px;
  right: 20px;
}
.share-video ul {
  float: left;
}
.share-video ul li {
  display: inline-block;
}
.share-video ul li a {
  width: 30px;
  height: 30px;
  background-color: rgb(199, 214, 253);
  border: 1px solid rgb(199, 214, 253);
  border-radius: 50%;
  text-align: center;
  padding: 6px 0px 0px;
  color: rgb(27, 34, 58);
}
.share-video ul li a:hover {
  color: rgb(199, 214, 253);
  background-color: transparent;
}
.send-money-wrapper {
  width: 100%;
  float: left;
}
.send-money-process {
  width: 100%;
  float: left;
  margin: -54px 0px 0px;
}
.send-money-tab {
  width: 70%;
  margin: auto;
}
.send-money-tab ul {
  width: 100%;
  float: left;
}
.send-money-tab ul li {
  width: 33.3333%;
  display: inline-block;
  text-align: center;
  float: left;
}
.send-money-tab ul li a {
  width: 100%;
  background-color: rgb(17, 28, 56);
  padding: 20px;
  float: left;
  font-size: 13px;
}
.send-money-tab ul li span {
  background-size: auto;
  background-repeat: no-repeat;
}
.send-money-tab ul li h5 {
  color: rgb(199, 214, 253);
}
/* .send-money-tab ul li:nth-child(1) span {
  background-image: url("../images/money-process-icon1.png");
} */
/* .send-money-tab ul li:nth-child(2) span {
  background-image: url("../images/money-process-icon2.png");
} */
/* .send-money-tab ul li:nth-child(3) span {
  background-image: url("../images/money-process-icon3.png");
} */
/* .send-money-tab ul li:nth-child(4) span {
  background-image: url("../images/money-process-icon4.png");
} */
.money-process-list-content1 {
  width: 100%;
  float: left;
  padding: 3em;
  position: relative;
}
.money-process-list-content1::after {
  bottom: 100%;
  left: 17%;
  border-style: solid;
  border-image: initial;
  content: "";
  height: 0px;
  width: 0px;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);
  border-width: 10px;
  margin-left: -15px;
}
.money-process-list-content2 {
  width: 100%;
  float: left;
  padding: 3em;
  position: relative;
}
.money-process-list-content2::after {
  bottom: 100%;
  left: 50%;
  border-style: solid;
  border-image: initial;
  content: "";
  height: 0px;
  width: 0px;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);
  border-width: 10px;
  margin-left: -15px;
}
.money-process-list-content3 {
  width: 100%;
  float: left;
  padding: 3em;
  position: relative;
}
.money-process-list-content3::after {
  bottom: 100%;
  left: 84%;
  border-style: solid;
  border-image: initial;
  content: "";
  height: 0px;
  width: 0px;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);
  border-width: 10px;
  margin-left: -15px;
}
.process-ol {
  width: 100%;
  float: left;
}
.process-ol ol {
  width: 100%;
  float: left;
}
.process-ol ol li {
  width: 25%;
  float: left;
  display: inline-block;
}
.process-ol ol li p {
  font-size: 12px;
  padding: 3px 0px;
}
.process-ol ol li h6 {
  font-size: 15px;
}
.process-ol ol li a {
  background-color: rgb(231, 112, 22);
  color: rgb(25, 39, 66);
  text-transform: uppercase;
  font-size: 12px;
  padding: 13px;
  border-radius: 4px;
  width: 100%;
  float: left;
  text-align: center;
}
.process-ol ol li a:hover {
  background-color: rgb(25, 39, 66);
  color: rgb(199, 214, 253);
}
.process-ol ol li h5 {
}
.process-ol p {
  color: rgb(25, 38, 66);
}
.process-ol h6 {
  color: rgb(25, 38, 66);
}
.process-ol h5 {
  color: rgb(25, 38, 66);
  width: 100%;
  float: left;
  padding: 20px 0px;
}
.process-ol input {
  /* min-height: 34px; */
  width: 100%;
  float: left;
  background-color: rgb(255, 255, 255);
  color: rgb(25, 38, 66);
  text-align: left;
  padding: 11px;
  border: 1px solid rgba(54, 54, 54, 0.2);
  border-radius: 4px;
}
.process-ol input[type="radio"] {
  min-height: 16px;
  width: 35px;
}
.process-ol input::placeholder {
  color: rgb(144, 144, 144);
}
.process-ol label {
  text-align: left;
  line-height: 18px;
  color: rgb(123, 123, 123);
  font-family: gothammedium, sans-serif;
  font-size: 13px;
  padding: 0px 0px 6px;
  width: 100%;
  float: left;
}
.send-money-process2 {
  width: 100%;
  float: left;
  margin: 0px;
  padding: 0px 0px 40px;
}
.send-money-tab2 {
  width: 70%;
  margin: auto;
}
.send-money-tab2 ul {
  width: 100%;
  float: left;
}
.send-money-tab2 ul li {
  width: 25%;
  display: inline-block;
  text-align: center;
  float: left;
  padding: 20px;
  background-color: rgb(25, 39, 66);
  border-right: 1px solid rgba(201, 216, 253, 0.21);
  transition: all 0.9s ease 0s;
}
.send-money-tab2 ul li a {
  width: 100%;
  background-color: rgb(17, 28, 56);
  padding: 20px;
  float: left;
  font-size: 13px;
}
.send-money-tab2 ul li span {
  background-size: auto;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  float: left;
}
.send-money-tab2 ul li h5 {
  color: rgb(199, 214, 253);
  padding: 15px 0px 0px;
}
/* .send-money-tab2 ul li:nth-child(1) span {
  background-image: url("../images/money-process-icon1.png");
} */
/* .send-money-tab2 ul li:nth-child(2) span {
  background-image: url("../images/money-process-icon2.png");
} */
/* .send-money-tab2 ul li:nth-child(3) span {
  background-image: url("../images/money-process-icon3.png");
} */
/* .send-money-tab2 ul li:nth-child(4) span {
  background-image: url("../images/money-process-icon4.png");
} */
.recipient-bank-details {
  width: 100%;
  float: left;
  background-color: rgb(241, 216, 189);
  margin: 20px 0px;
  border-radius: 4px;
  padding: 20px !important;
}
.recipient-bank-details h3 {
  color: rgb(27, 34, 58);
}
.recipient-bank-details p {
  color: rgb(27, 34, 58);
  padding: 10px 0px;
}
.recipient-header2 {
  width: 100%;
  float: left;
  padding: 20px 0px 0px;
}
.recipient-header2 h3 {
  color: rgb(27, 34, 58);
  font-size: 15px;
}
.add-send-total {
  background-color: rgb(247, 247, 247);
  padding: 0px 10px;
}
.payment-details-add {
  background-color: rgb(199, 214, 253);
  padding: 10px;
  width: 100%;
  float: left;
}
.payment-details-add img {
  float: left;
  width: 23px;
}
.payment-details-add h5 {
  width: auto;
  float: left;
  color: rgb(27, 34, 58);
  font-size: 15px;
  padding: 5px 0px 0px 20px;
  font-family: gothambold, sans-serif;
}
.payment-details-add2 {
  padding: 10px 0px;
  width: 100%;
  float: left;
}
.payment-details-add2 img {
  float: left;
  width: 23px;
}
.payment-details-add2 h2 {
  float: left;
  color: rgb(27, 34, 58);
  font-size: 15px;
  padding: 5px 0px 0px 20px;
  font-family: gothambold, sans-serif;
}
.country-value {
}
.country-value label {
}
.country-value input {
  background-color: rgb(199, 214, 253);
}
.continue-cancel {
  width: 100%;
  float: left;
  padding: 30px 0px;
}
.continue-cancel button {
  transition: all 0.9s ease 0s;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 13px 10px;
  width: 48%;
  margin: 1%;
}
.continue-cancel button.continue-payment {
  background-color: rgb(227, 227, 227);
  color: rgb(102, 102, 102);
  float: left;
}
.continue-cancel button.continue-payment:hover {
  background-color: rgb(27, 34, 58);
  color: rgb(255, 255, 255);
}
.continue-cancel button.cancel-payment {
  background-color: rgb(231, 112, 22);
  color: rgb(255, 255, 255);
  float: right;
}
.continue-cancel button.cancel-payment:hover {
  background-color: rgb(27, 34, 58);
  color: rgb(255, 255, 255);
}
.continue-cancel button:hover {
  transition: all 0.9s ease 0s;
}
.transfer-type-image {
  float: left;
}
.transfer-type-image figure {
  width: 32px;
  height: 32px;
  background-color: rgb(242, 245, 247);
  padding: 5px;
  border-radius: 50%;
}
.transfer-type-image figure img {
}
.transfer-type-details {
  float: left;
  text-align: left;
  padding: 0px 0px 0px 30px;
}
.transfer-type-details h4 {
  color: rgb(26, 34, 58);
  font-size: 14px;
}
.transfer-type-details p {
  color: rgb(122, 122, 122);
  padding: 1px 0px;
}
.transfer-type-details b {
  font-family: gothambold_italic, sans-serif;
  font-style: italic;
  color: rgb(231, 112, 22);
}
.full-input {
  width: 100%;
  float: left;
}
.add-back-next {
  width: 98%;
  float: left;
  padding: 20px 0px;
  border-top: 1px dashed rgb(216, 216, 216);
  margin: 1%;
}
.add-back-next ul {
  width: 100%;
  float: left;
}
.add-back-next ul li {
  width: auto;
}
.add-back-next ul li:nth-child(2n + 1) {
  float: left;
}
.add-back-next ul li:nth-child(2n) {
  float: right;
}
.add-back-next ul li:nth-child(2n + 1) a {
  padding: 13px 50px;
  color: rgb(102, 102, 102);
  background-color: rgb(227, 227, 227);
  border-radius: 4px;
}
.add-back-next ul li:nth-child(2n) a {
  padding: 13px 50px;
  color: rgb(255, 255, 255);
  background-color: rgb(231, 112, 22);
  border-radius: 4px;
}
.add-back-next ul li:nth-child(2n + 1) a:hover {
  background-color: rgb(17, 28, 56);
  color: rgb(255, 255, 255);
}
.add-back-next ul li:nth-child(2n) a:hover {
  background-color: rgb(17, 28, 56);
  color: rgb(255, 255, 255);
}
.add-label-input {
  margin: 10px 0px;
  width: 100%;
  float: left;
}
.money-half-input {
  padding: 10px;
  text-align: left;
}
.money-dropdown {
  width: 100%;
  float: left;
  border: 1px solid rgba(54, 54, 54, 0.2);
  border-radius: 4px;
  padding: 6px 2px 6px 0px;
}
.output-results {
}
.output-results input {
  background-color: rgb(238, 210, 185);
  color: rgb(102, 102, 102);
}
.send-total-wrapper {
  width: 100%;
  float: left;
  padding: 40px 10px;
}
.send-total-wrapper ol {
  width: 100%;
  float: left;
}
.send-total-wrapper ol li {
  width: 100%;
  float: left;
  padding: 10px 0px;
}
.send-total-wrapper ol li h3 {
  text-align: left;
  float: left;
  color: rgb(100, 100, 100);
  font-size: 14px;
  font-family: gothammedium, sans-serif;
}
.send-total-wrapper ol li p {
  float: right;
  color: rgb(100, 100, 100);
}
.send-fees {
  width: 100%;
  float: left;
  padding: 20px 30px;
}
.total-pay {
  width: 100%;
  float: left;
  padding: 20px 30px;
  border-top: 1px dashed rgb(199, 199, 199);
}
.money-dropdown button {
}
.continue-process {
  width: 100%;
  float: left;
  text-align: center;
  margin: auto;
  padding: 20px 0px;
}
.continue-process a,
.continue-process button {
  background-color: rgb(231, 112, 22);
  padding: 13px 40px;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  font-size: 13px;
}
.continue-process a:hover,
.continue-process button:hover {
  background-color: rgb(27, 34, 58);
}
.mobile-wallet {
  width: 100%;
  float: left;
}
.mobile-wallet ul {
  width: 100%;
  float: left;
  text-align: center;
}
.mobile-wallet ul li {
  display: inline-block;
}
.mobile-wallet ul li img {
  width: 121px;
}
.send-to-country {
  width: 100%;
  float: left;
  padding: 0px 11px;
}
.send-to-country h4 {
  float: left;
  padding: 7px 16px 0px 0px;
}
.send-to-country figure {
  float: left;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid rgb(232, 232, 232);
}
.send-to-country figure img {
}
.single-blog-wrapper {
  width: 100%;
  float: left;
}
.single-blog-list-details {
  width: 100%;
  float: left;
}
.single-blog-details {
  width: 70%;
  float: left;
  padding: 50px;
}
.single-blog-details img {
  width: 100%;
  float: left;
}
.single-blog-details h6 {
  width: 100%;
  float: left;
  color: rgb(54, 54, 54);
  padding: 20px 0px 0px;
}
.single-blog-details h2 {
  color: rgb(54, 54, 54);
  padding: 10px 0px 20px;
  width: 100%;
  float: left;
}
.single-blog-details b {
  float: left;
  width: 30px;
  height: 1px;
  background-color: rgb(54, 54, 54);
}
.single-blog-details p {
  color: rgb(122, 122, 122);
  padding: 10px 0px;
  width: 100%;
  float: left;
}
.single-blog-details iframe {
  width: 100%;
  float: left;
  display: block;
  height: 500px;
}
.share-blog {
  width: 100%;
  float: left;
  padding: 30px 0px;
}
.share-blog h5 {
  float: left;
  color: rgb(27, 34, 58);
  padding: 18px 0px 2px;
}
.share-blog ul {
  float: left;
  padding: 10px;
}
.share-blog ul li {
  display: inline-block;
}
.share-blog ul li a {
  width: 30px;
  height: 30px;
  color: rgb(199, 214, 253);
  border: 1px solid rgb(27, 34, 58);
  border-radius: 50%;
  text-align: center;
  padding: 5px 0px 0px 2px;
  background-color: rgb(27, 34, 58);
}
.share-blog ul li a:hover {
  color: rgb(27, 34, 58);
  background-color: transparent;
}
.single-blog-related-posts {
  width: 30%;
  float: left;
  padding: 50px;
}
.single-blog-related-posts h4 {
  color: rgb(27, 34, 58);
  text-transform: uppercase;
  font-size: 14px;
}
.single-blog-related-posts ul {
  width: 100%;
  float: left;
  padding: 20px 0px;
}
.single-blog-related-posts ul li {
  width: 100%;
  float: left;
}
.single-blog-related-posts ul li a {
  width: 100%;
  float: left;
  font-size: 13px;
  padding: 18px 0px 5px;
  color: rgb(122, 122, 122);
  border-bottom: 1px solid rgb(241, 241, 241);
}
.single-blog-related-posts ul li a:hover {
  color: rgb(26, 34, 58);
  border-bottom: 1px solid rgb(26, 34, 58);
}
.single-blog-related-posts ul li a.active-related-blog-link {
  color: rgb(26, 34, 58);
  border-bottom: 1px solid rgb(26, 34, 58);
}
.working-content {
  width: 70%;
  margin: auto;
}
.working-content-paragraph {
  width: 100%;
  float: left;
}
.working-content-paragraph p {
  width: 100%;
  float: left;
  padding: 10px 0px;
  color: rgb(122, 122, 122);
}
.working-content-paragraph p a {
  color: rgb(242, 128, 32);
}
.working-content-paragraph p a:hover {
  color: rgb(19, 25, 43);
}
.working-content-paragraph ul {
  width: 100%;
  float: left;
  display: list-item;
}
.working-content-paragraph ul li {
  font-family: gothambook, sans-serif;
  color: rgb(110, 110, 111);
  font-size: 13px;
  line-height: 22px;
  list-style: inside;
}
.working-content iframe {
  width: 100%;
  height: 500px;
  float: left;
  display: block;
  margin: 20px 0px;
}
.get-started-button {
  width: 100%;
  float: left;
  padding: 3em 0px;
}
.get-started-button a {
  background-color: rgb(242, 128, 32);
  color: rgb(255, 255, 255);
  font-size: 13px;
  padding: 13px 50px;
  border-radius: 4px;
}
.get-started-button a:hover {
  background-color: rgb(25, 32, 55);
}
.footer-wrapper {
  /* flex: 0 0 10%; */
  width: 100%;
  float: left;
  background-image: url("../images/footer-bg.jpg");
  background-size: contain;
  background-repeat: repeat;
  position: relative;
  bottom: 0;
}
.position-footer {
  bottom: inherit;
  position: relative;
  padding: 5px 0;
}
.bottom-footer-wrapper {
  width: 100%;
  float: left;
}
.footer-copyright {
  width: 33.333%;
  float: left;
}
.footer-copyright p {
  color: rgb(255, 255, 255);
  text-align: left;
  padding: 8px 20px 3px;
  font-size: 11px;
  text-transform: uppercase;
}
.footer-links {
  width: 33.33%;
  float: right;
}
.footer-links ul {
  width: 100%;
  float: right;
  text-align: center;
}
.footer-links ul li {
  display: inline-block;
  border-right: 1px solid rgba(199, 214, 253, 0.3);
}
.footer-links ul li:last-child {
  border-right: none;
}
.footer-links ul li a {
  color: rgb(199, 214, 253);
  padding: 10px;
  float: left;
  font-size: 12px;
  border-bottom: 1px solid transparent;
}
.footer-links ul li a:hover {
  color: rgb(255, 255, 255);
}
.footer-links ul li a.active-footer-link {
  color: rgb(255, 255, 255);
  border-bottom: 1px solid rgba(199, 214, 253, 0.3);
}
.lets-talk {
}
.go-top {
  position: fixed;
  bottom: 37px;
  right: 0px;
  margin: auto;
  text-decoration: none;
  color: rgb(255, 255, 255);
  background-color: rgb(33, 33, 33);
  font-size: 25px;
  padding: 6px 2px 2px;
  display: none;
  font-weight: bolder;
  width: 35px;
  height: 35px;
  text-align: center;
}
.go-top p {
  display: none;
}
.go-top i {
}
.go-top:hover {
  background-color: rgb(250, 0, 0);
  color: rgb(255, 255, 255);
}
.continput {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  width: 100%;
  height: auto;
  padding: 16px 40px 16px 10px;
  box-sizing: border-box;
  float: left;
}
.continput ul {
  list-style-type: none;
  width: 100%;
  margin: auto;
}
.continput ul li {
  position: relative;
  padding: 10px;
  height: auto;
  width: 100%;
  border-bottom: 1px dashed rgb(189, 189, 189);
}
.continput ul li label::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  position: absolute;
  right: 7px;
  top: calc(61% - 13px);
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid rgb(203, 203, 203);
}
.continput ul li input[type="radio"] {
  opacity: 0;
  appearance: none;
  display: inline-block;
  vertical-align: middle;
  z-index: 100;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 60px;
  position: absolute;
  left: 0px;
  top: calc(43% - 18px);
  cursor: pointer;
}
.continput ul li .bullet {
  position: relative;
  width: 25px;
  height: 25px;
  right: 2px;
  top: 29px;
  border: 2px solid rgb(231, 112, 22);
  opacity: 0;
  border-radius: 50%;
}
.continput ul li input[type="radio"]:checked ~ .bullet {
  position: absolute;
  opacity: 1;
  animation-name: explode;
  animation-duration: 0.35s;
}
.continput ul li input[type="radio"]:checked ~ label::before {
  width: 10px;
  height: 10px;
  background: rgb(231, 112, 22);
  right: 10px;
  top: calc(64% - 12px);
  border: none;
}
.continput ul li input[type="radio"]:checked ~ .transfer-type-image figure {
  background-color: rgb(231, 112, 22);
}
.line {
  position: absolute;
  width: 10px;
  height: 2px;
  background-color: rgb(255, 255, 255);
  opacity: 0;
}
.line.zero {
  left: 11px;
  top: -21px;
  transform: translateY(20px);
  width: 2px;
  height: 10px;
}
.line.one {
  right: -7px;
  top: -11px;
  transform: rotate(-55deg) translate(-9px);
}
.line.two {
  right: -20px;
  top: 11px;
  transform: translate(-9px);
}
.line.three {
  right: -8px;
  top: 35px;
  transform: rotate(55deg) translate(-9px);
}
.line.four {
  left: -8px;
  top: -11px;
  transform: rotate(55deg) translate(9px);
}
.line.five {
  left: -20px;
  top: 11px;
  transform: translate(9px);
}
.line.six {
  left: -8px;
  top: 35px;
  transform: rotate(-55deg) translate(9px);
}
.line.seven {
  left: 11px;
  bottom: -21px;
  transform: translateY(-20px);
  width: 2px;
  height: 10px;
}
.continput ul li input[type="radio"]:checked ~ .bullet .line.zero {
  animation-name: drop-zero;
  animation-delay: 0.1s;
  animation-duration: 0.9s;
  animation-fill-mode: forwards;
}
.continput ul li input[type="radio"]:checked ~ .bullet .line.one {
  animation-name: drop-one;
  animation-delay: 0.1s;
  animation-duration: 0.9s;
  animation-fill-mode: forwards;
}
.continput ul li input[type="radio"]:checked ~ .bullet .line.two {
  animation-name: drop-two;
  animation-delay: 0.1s;
  animation-duration: 0.9s;
  animation-fill-mode: forwards;
}
.continput ul li input[type="radio"]:checked ~ .bullet .line.three {
  animation-name: drop-three;
  animation-delay: 0.1s;
  animation-duration: 0.9s;
  animation-fill-mode: forwards;
}
.continput ul li input[type="radio"]:checked ~ .bullet .line.four {
  animation-name: drop-four;
  animation-delay: 0.1s;
  animation-duration: 0.9s;
  animation-fill-mode: forwards;
}
.continput ul li input[type="radio"]:checked ~ .bullet .line.five {
  animation-name: drop-five;
  animation-delay: 0.1s;
  animation-duration: 0.9s;
  animation-fill-mode: forwards;
}
.continput ul li input[type="radio"]:checked ~ .bullet .line.six {
  animation-name: drop-six;
  animation-delay: 0.1s;
  animation-duration: 0.9s;
  animation-fill-mode: forwards;
}
.continput ul li input[type="radio"]:checked ~ .bullet .line.seven {
  animation-name: drop-seven;
  animation-delay: 0.1s;
  animation-duration: 0.9s;
  animation-fill-mode: forwards;
}
@keyframes explode {
  0% {
    opacity: 0;
    transform: scale(10);
  }
  60% {
    opacity: 1;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes drop-zero {
  0% {
    opacity: 0;
    transform: translateY(20px);
    height: 10px;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translateY(-2px);
    height: 0px;
    opacity: 0;
  }
}
@keyframes drop-one {
  0% {
    opacity: 0;
    transform: rotate(-55deg) translate(-20px);
    width: 10px;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: rotate(-55deg) translate(9px);
    width: 0px;
    opacity: 0;
  }
}
@keyframes drop-two {
  0% {
    opacity: 0;
    transform: translate(-20px);
    width: 10px;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translate(9px);
    width: 0px;
    opacity: 0;
  }
}
@keyframes drop-three {
  0% {
    opacity: 0;
    transform: rotate(55deg) translate(-20px);
    width: 10px;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: rotate(55deg) translate(9px);
    width: 0px;
    opacity: 0;
  }
}
@keyframes drop-four {
  0% {
    opacity: 0;
    transform: rotate(55deg) translate(20px);
    width: 10px;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: rotate(55deg) translate(-9px);
    width: 0px;
    opacity: 0;
  }
}
@keyframes drop-five {
  0% {
    opacity: 0;
    transform: translate(20px);
    width: 10px;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translate(-9px);
    width: 0px;
    opacity: 0;
  }
}
@keyframes drop-six {
  0% {
    opacity: 0;
    transform: rotate(-55deg) translate(20px);
    width: 10px;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: rotate(-55deg) translate(-9px);
    width: 0px;
    opacity: 0;
  }
}
@keyframes drop-seven {
  0% {
    opacity: 0;
    transform: translateY(-20px);
    height: 10px;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translateY(2px);
    height: 0px;
    opacity: 0;
  }
}
.error-content {
  width: 100%;
  float: left;
}
.error-content figure {
}
.error-content figure img {
  width: 120px;
}
.error-link {
  width: 100%;
  float: left;
  margin: auto;
  text-align: center;
  padding: 40px 0px;
}
.error-link a {
  background-color: rgb(231, 112, 22);
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-size: 12px;
  padding: 13px 40px;
  border-radius: 6px;
}
.error-link a:hover {
  color: rgb(231, 112, 22);
  background-color: rgb(255, 255, 255);
}
#menuToggle {
  display: block;
  position: absolute;
  top: 50px;
  right: 50px;
  z-index: 1;
  user-select: none;
}
#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}
#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: rgb(25, 39, 66);
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1) 0s, background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1) 0s,
    opacity 0.55s ease 0s;
}
#menuToggle span:first-child {
  transform-origin: 0% 0%;
}
#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}
#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: rgb(255, 255, 255);
}
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
#menuToggle input:checked ~ span:nth-last-child(2) {
  opacity: 1;
  transform: rotate(-45deg) translate(0px, -1px);
}
#menu {
  position: fixed;
  width: 320px;
  height: 100vh;
  margin: -77px 0px 0px;
  padding: 125px 0px 0px;
  right: 0px;
  background: rgb(25, 39, 66);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(100%, 0px);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1) 0s;
}
#menu li {
  width: 100%;
  float: left;
}
#menu li a {
  width: 100%;
  float: left;
  padding: 10px;
  border-bottom: 1px solid rgba(198, 213, 251, 0.22);
}
#menu li a img {
  width: 37px;
  float: left;
}
#menu li a h6 {
  color: rgb(199, 214, 253);
  padding: 10px 0px 0px 20px;
  float: left;
}
#menu li a:hover {
  background-color: rgb(11, 17, 29);
}
#menu li a.active-user-dash-link {
  background-color: rgb(11, 17, 29);
}
#menuToggle input:checked ~ ul {
  transform: scale(1, 1);
  opacity: 1;
}
.steps-inputs-wrapper {
  width: 100%;
  float: left;
  padding: 20px;
}
.steps-inputs-wrapper button {
  float: left;
  text-align: center;
}
.steps-inputs-wrapper button i {
  float: right;
  padding: 0px 0px 0px 40px;
}
.steps-inputs-column1 button {
  background-color: rgb(231, 112, 22);
  padding: 13px 40px;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  font-size: 13px;
  outline: none;
  border: none;
}
.steps-inputs-column1 button:hover {
  background-color: rgb(27, 34, 58);
}
.steps-inputs-column1 a,
.send-money-btn {
  background-color: rgb(231, 112, 22) !important;
  padding: 13px 40px;
  border-radius: 4px !important;
  color: rgb(255, 255, 255) !important;
  font-size: 13px;
  outline: none;
  border: none;
  text-decoration: none !important;
}
.steps-inputs-column1 a:hover,
.send-money-btn:hover {
  background-color: rgb(27, 34, 58);
}

.send-money-btn:disabled {
  background-color: #ccc !important;
  cursor: not-allowed !important;
  color: #eee !important;
}
.steps-inputs-column2 button {
  border-radius: 4px;
  background-color: rgb(227, 227, 227);
  color: rgb(102, 102, 102);
  padding: 13px 40px;
  font-size: 13px;
}
.steps-inputs-column2 button:hover {
  background-color: rgb(231, 112, 22);
  color: rgb(255, 255, 255);
}
.steps-inputs-column3 button {
  background-color: rgb(199, 214, 253);
  color: rgb(27, 34, 58);
  border-radius: 4px;
  padding: 13px 40px;
  font-size: 13px;
}
.steps-inputs-column3 button:hover {
  background-color: rgb(27, 34, 58);
  color: rgb(199, 214, 253);
}
.ongoing-transaction-wrapper {
  width: 100%;
  float: left;
  padding: 40px 10em;
}
.uploaded-image-wrapper {
  width: 100%;
  float: right;
  border: 1px solid rgba(50, 50, 50, 0.32);
  border-radius: 8px;
  padding: 20px 0px 0px;
  margin: 10px 0px;
}
.uploaded-image-wrapper img {
  width: 100px;
}
.uploaded-image-wrapper input {
  width: 100%;
  background-color: rgb(66, 114, 180);
  padding: 8px;
  border-radius: 8px;
  margin-top: 20px;
}
.file-upload {
  display: block;
  text-align: center;
  font-family: latomedium;
  font-size: 12px;
}
.file-upload .file-select {
  display: block;
  border: 1px solid rgb(220, 228, 236);
  color: rgb(33, 54, 80);
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: left;
  background: transparent;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  margin-top: 20px;
  width: 100%;
}
.file-upload .file-select .file-select-button {
  background: rgb(220, 228, 236);
  padding: 0px 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  font-family: gothamregular, sans-serif;
}
.file-upload .file-select .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0px 10px;
  font-family: gothamregular, sans-serif;
}
.file-upload .file-select:hover {
  border-color: rgb(52, 73, 94);
  transition: all 0.2s ease-in-out 0s;
}
.file-upload .file-select:hover .file-select-button {
  background: rgb(52, 73, 94);
  color: rgb(255, 255, 255);
  transition: all 0.2s ease-in-out 0s;
}
.file-upload.active .file-select {
  border-color: rgb(63, 164, 106);
  transition: all 0.2s ease-in-out 0s;
}
.file-upload.active .file-select .file-select-button {
  background: rgb(63, 164, 106);
  color: rgb(255, 255, 255);
  transition: all 0.2s ease-in-out 0s;
}
.file-upload .file-select input[type="file"] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  opacity: 0;
}
.file-upload .file-select.file-select-disabled {
  opacity: 0.65;
}
.file-upload .file-select.file-select-disabled:hover {
  display: block;
  border: 2px solid rgb(220, 228, 236);
  color: rgb(52, 73, 94);
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  background: rgb(255, 255, 255);
  overflow: hidden;
  position: relative;
}
.file-upload .file-select.file-select-disabled:hover .file-select-button {
  background: rgb(220, 228, 236);
  color: rgb(102, 102, 102);
  padding: 0px 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}
.file-upload .file-select.file-select-disabled:hover .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0px 10px;
}
.user-profile-details {
  width: 100%;
  float: left;
}
.user-profile-image {
  width: 100%;
  float: left;
  margin: auto;
  text-align: center;
}
.user-profile-bg {
  width: 100%;
  float: left;
  height: 100px;
  background-image: url("../images/home-slider-bg2.jpg");
  background-size: contain;
  background-repeat: repeat;
}
.user-profile-image figure {
  margin: -70px 0px 0px;
  width: 100%;
  float: left;
}
.user-profile-image img {
  width: 120px;
}
.user-profile-name {
  width: 100%;
  float: left;
  text-align: center;
  padding: 20px;
}
.user-profile-name h3 {
  color: rgb(70, 70, 70);
  font-family: gothammedium, sans-serif;
}
.add-beneficiary-wrapper {
}
.resp-tab-content {
  background-color: transparent !important;
}
.instruction-text {
  padding: 10px;
}
.transactions-wrapper {
  width: 100%;
  float: left;
  padding: 30px 6em;
}
.transaction-table {
  width: 100% !important;
  float: left;
  overflow-x: auto;
}
.transaction-table table {
  width: 100%;
  float: left;
}
.transaction-table table tr {
}
.transaction-table table th {
  padding: 10px;
  text-align: left;
  border: 1px solid rgb(228, 228, 228);
  font-family: gothammedium, sans-serif;
  font-size: 14px;
  color: rgb(231, 112, 22);
}
.transaction-table table td {
  padding: 10px;
  line-height: normal;
  text-align: left;
  border: 1px solid rgb(228, 228, 228);
  font-family: gothamregular, sans-serif;
  font-size: 13px;
  color: rgb(144, 144, 144);
}
.transaction-table table td span {
}
.pagination-app {
  width: 100%;
  float: left;
  padding: 30px 0px;
}
.pagination-app ul {
  width: 100%;
  float: left;
  text-align: center;
  margin: auto;
  margin-bottom: 150px;
}
.pagination-app ul li {
  display: inline-block;
  margin: 0 5px;
}
.pagination-app ul li span {
  font-size: 12px;
  width: 30px;
  height: 30px;
  float: left;
  text-align: center;
  border: 1px solid rgb(234, 234, 234);
  border-radius: 50%;
  padding: 8px 0px 0px;
  color: rgb(68, 68, 68);
}
.pagination-app ul li span:hover {
  background-color: rgb(189, 161, 161);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(68, 68, 68);
}
.beneficiary-details-wrapper {
  width: 100%;
  float: left;
  padding: 30px 5em;
}
.payment-option-image {
}
.payment-option-image img {
  width: 120px;
}
.payment-option-text {
  padding: 10px;
  text-align: center;
}
.create-account-tabs {
  width: 100%;
  float: left;
  padding: 40px 0 0 0;
}
.create-account-tabs ul {
  width: 100%;
  float: left;
  text-align: center;
  margin: auto !important;
}
.create-account-tabs ul li {
  float: initial;
  padding: 10px 40px;
  background-color: #fff;
}
.create-account-tabs ul li span {
}
.create-account-tabs ul li h5 {
  font-size: 13px;
  color: rgb(26, 34, 58);
}
@media handheld, only screen and (max-width: 1130px) {
}
@media handheld, only screen and (max-width: 1024px) {
  .about-details-columns {
    padding: 30px;
  }
  .trusted-partners-wrapper {
    padding: 42px;
  }
  .float-element-left {
    display: none;
  }
  .float-element-right {
    display: none;
  }
  .about-mission-vision {
    padding: 20px;
  }
  .media-lists {
    padding: 20px;
  }
  .create-account-content {
    width: 70%;
  }
  .faqs-search-form form input {
    width: 70%;
  }
  .form-social {
    width: 100%;
    float: left;
    margin: 10px 0px;
  }
  .single-blog-details {
    padding: 10px;
  }
  .single-blog-related-posts {
    padding: 10px;
  }
  .working-content {
    width: 100%;
    padding: 20px;
    float: left;
  }
  .send-money-tab {
    width: 100%;
    float: left;
  }
  .send-money-tab2 {
    width: 100%;
    float: left;
  }
  .about-networks {
    padding: 20px;
  }
  .about-testimonials {
    padding: 20px;
  }
  .auto-answers {
    width: 70%;
  }
  .ongoing-transaction-wrapper {
    padding: 20px;
  }
  .beneficiary-details-wrapper {
    padding: 20px 10px;
  }
  .transactions-wrapper {
    padding: 20px;
  }
}
@media handheld, only screen and (max-width: 960px) {
  /* table, thead, tbody, th, td, tr { display: block; } */
  /* table thead tr { position: absolute; top: -9999px; left: -9999px; } */
  /* table tr { width: 100%; float: left; text-align: center; } */
  /* table td { border-top: none; border-right: none; border-left: none; border-image: initial; border-bottom: 1px solid rgb(238, 238, 238); position: relative;  width: 100%; font-size: 11px; padding: 2em; } */
  /* table td::before { position: absolute; top: 6px; left: 6px; width: 45%; padding-right: 10px; white-space: nowrap; } */
  /* table th.wdh { width: 100%; float: left; } */
  /* .transaction-table table { position: relative; } */
  .best-choice-lists ul li {
    width: 50%;
  }
  /* .create-account-form form {
    height: 100%;
    width: 100%;
    float: left;
    padding: 0px;
  } */
  /* .forex-form form {
    width: 100%;
    float: left;
    padding: 0px;
  } */
  .footer-copyright {
    width: 100%;
  }
  .footer-links {
    width: 100%;
  }
  .about-upesi-header h3 {
    width: auto;
  }
  .media-header h3 {
    width: auto;
  }
  .contact-header h3 {
    width: auto;
  }
  /* .faqs-header h3 {
    width: auto;
  } */
  .money-header h3 {
    width: auto;
  }
  .privacy-header h3 {
    width: auto;
  }
  .about-details-column {
    width: 100%;
    padding: 5px;
  }
  .media-search-form form input[type="search"] {
    width: 90%;
  }
  .contact-content {
    width: 100%;
    float: left;
    padding: 10px;
  }
  .create-account-content {
    width: 100%;
    float: left;
  }
  .faqs-banner {
    padding: 10px;
  }
  .faqs-search-form form input {
    width: 90%;
  }
  .faqs-lists {
    width: 100%;
    padding: 10px;
  }
  .faqs-details {
    width: 100%;
    padding: 10px;
  }
  .faqs-details iframe {
    height: 300px;
  }
  .single-blog-details {
    width: 100%;
  }
  .single-blog-related-posts {
    width: 100%;
  }
  .single-blog-details iframe {
    height: 300px;
  }
  .working-content iframe {
    height: 300px;
  }
  /* .money-top-search {
    padding: 10px;
    height: 60px;
  } */
  .send-money-tab ul li a {
    padding: 10px;
  }
  .send-money-tab ul li h5 {
    font-size: 12px;
  }
  .money-process-list-content1 {
    padding: 10px;
  }
  .money-process-list-content2 {
    padding: 10px;
  }
  .money-process-list-content3 {
    padding: 10px;
  }
  .send-money-process {
    margin: 0px;
  }
  .process-ol ol li {
    width: 50%;
  }
  .continput {
    padding: 0px;
  }
  .network-map-container {
    width: 100%;
  }
  .network-key {
    width: 100%;
  }
  .testimonial-table {
    width: 100%;
    float: left;
    display: block;
  }
  .wrapper {
    width: 100%;
    float: left;
    display: block;
  }
  .auto-answers {
    width: 100%;
    float: left;
  }
  .privacy-banner {
    padding: 10px;
  }
  .terms-banner {
    padding: 10px;
  }
  .transaction-table table tr {
    margin: 20px 0px;
  }
}
@media handheld, only screen and (max-width: 768px) {
  .security-wrapper {
    margin-top: 132px;
  }
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }

  body h2 {
    font-size: 16px;
    font-family: gothamblack, sans-serif;
  }
  .custom-grid-size {
    display: block !important;
    clear: both !important;
  }
  .custom-grid-size .w-50 {
    width: 100% !important;
  }
  .modal-body {
    min-width: 100% !important;
  }
  .custom-grid-size .w-50:first-child {
    max-width: 100% !important;
    border-right: 0px solid #ccc !important;
    margin-right: 0px !important;
    padding-right: 0px !important;
  }
  .sendMoney-mobile-hide {
    display: none !important;
  }

  .sendMoney-mobile-show {
    display: block !important;
  }

  .dashboard-wrapper .form-control {
    display: block !important;
    float: none !important;
  }
  .go-top p {
    display: block;
    float: left;
    color: rgb(255, 255, 255);
    font-weight: bold;
  }
  .go-top i {
    float: right;
    color: rgb(255, 255, 255);
    font-weight: bold;
  }
  .go-top {
    position: fixed;
    float: left;
    width: 100%;
    bottom: 0px;
    right: 0px;
    border-radius: 0px;
    padding: 10px;
  }
  /* .desktop-header-wrapper { display: block; padding: 0px; } */
  /* .mobile-header-wrapper { display: none; } */
  /* .desktop-header-wrapper{display: none;} */
  .desktop-header-wrapper2 {
    display: none;
  }
  .mobile-header-wrapper {
    display: block;
    z-index: 99999;
    background: #fff;
  }
  .best-choice-lists ul li {
    width: 100%;
  }
  .about-details-colum {
    width: 100%;
  }
  .mission-vision-column {
    width: 98%;
    height: auto;
  }
  .header-account-wrapper {
    width: 100%;
    text-align: left;
    padding: 0px 0px 0px;
  }
  .header-account-wrapper a {
    width: 50%;
    float: left;
    border-top: 1px solid rgb(212, 212, 212);
    padding: 10px;
  }
  .header-country {
    width: 50%;
    padding: 10px;
  }
  .bottom-footer-wrapper {
    margin: 0px 0px 40px;
  }
  .footer-copyright p {
    padding: 10px;
  }
  .footer-links ul li a {
    padding: 6px;
    font-size: 11px;
  }
  .slider-copy {
    position: unset;
    width: 100%;
    float: left;
    padding: 20px;
    background-color: rgb(231, 112, 22);
  }
  .slider-copy h1 {
    font-size: 1.5em;
    width: 100%;
    float: left;
    letter-spacing: 5px;
    text-align: center;
    padding: 0px;
    display: block;
  }
  .slider-copy h1 br {
    display: none;
  }
  .slider-copy i {
    width: 100%;
    float: left;
    display: block;
  }
  .slider-copy a {
    width: 100%;
    float: left;
    margin: 0px;
    text-align: center;
  }
  .slider-image {
    width: 100%;
  }
  .contact-information ul li {
    width: 100%;
    display: block;
    float: left;
    border-bottom: 1px solid rgba(199, 214, 253, 0.37);
    border-right: none;
  }
  .half-input {
    width: 100%;
  }
  .third-input {
    width: 100%;
  }
  .contact-submit button {
    width: 100%;
  }
  .form-social form {
    width: 100%;
  }
  .faqs-social-lists {
    width: 100%;
    padding: 20px 0px;
  }
  .header-wrapper::after {
    background: none;
  }
  .continue-cancel button {
    width: 100%;
    margin: 10px 0px;
  }
  .continue-process a {
    width: 100%;
    float: left;
  }
  .process-ol ol li {
    width: 100%;
  }
  .testimony-content-bg {
    width: 300px;
    height: 300px;
    padding: 20px;
    border: 5px solid rgb(255, 255, 255);
  }
  .testimonials-header h2 {
    font-size: 1.5em;
  }
  .testimonials-header h2 br {
    display: none;
  }
  .testimonials-header p br {
    display: none;
  }
  ul#continents3 li {
    display: none;
  }
  ul#continents3 {
    float: left;
  }
  .testimony-content figure img {
    width: 40px;
  }
  .testimony-content p {
    padding: 10px 0px;
  }
  /* .create-account-form {
    height: 100%;
    padding-bottom: 50px;
    background-color: #111c38;
  } */
  .home-slider-form button {
    padding: 14px 9px;
  }
  .video-play-control {
    top: 20%;
  }
  .logo-wrapper {
    width: 50%;
    padding: 10px;
  }
  .steps-inputs-wrapper a {
    width: 100%;
    float: left;
    margin: 5px 0px;
  }
  .logo-wrapper img {
    float: left;
    width: 50px;
  }
  h2.resp-accordion h5 {
    color: rgb(31, 43, 98);
  }
  /* .forex-form {
    padding: 20px 0;
  } */
  .dropdown .dropbtn span.value {
    padding: 5px;
  }
  .about-banner-image {
    width: 85%;
  }
  #menu {
    position: fixed !important;
    bottom: 0;
  }
  #menuToggle {
    top: 20px;
    right: 20px;
  }
  .resp-tab-content {
    padding: 20px 0px !important;
  }
  .send-total-wrapper {
    width: 100%;
    float: left;
    padding: 20px 10px;
  }
  .send-total-wrapper ol li {
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
  }
  .send-total-wrapper ol li h3 {
    width: 50%;
    text-align: left;
    line-height: 20px;
  }
  .send-total-wrapper ol li p {
    width: 50%;
    text-align: right;
  }
  .send-fees,
  .total-pay {
    padding: 20px 10px;
  }
  .transhistory-half-input {
    width: 100% !important;
    padding: 10px;
  }
  .mob-column {
    flex-direction: column !important;
  }
  .continue-process {
    flex-direction: column-reverse;
    width: 100%;
    float: left;
    text-align: center;
    margin: auto;
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
  }
  .continue-process > .steps-inputs-column2,
  .continue-process > .steps-inputs-column1,
  .continue-process > .steps-inputs-column3 {
    width: 100%;
    margin-bottom: 10px;
  }
  .transaction-table table td {
    text-align: center;
  }
  .div-60 {
    width: 100% !important;
    float: left;
    margin-top: 10px;
  }
  .div-40 {
    width: 100% !important;
    float: left;
    margin-top: 10px;
  }
  .exchangerate-card {
    min-height: 210px !important;
    height: 100%;
  }
  .exchangerate-card table td {
    border: 0 !important;
  }
  .exchg-rate {
    width: 100% !important;
    text-align: left;
    min-width: 60px !important;
  }
  .exchg-rate p {
    font-size: 16px;
    font-weight: 600;
  }
  .currency-card h4 {
    font-size: 16px !important;
    font-weight: 700;
    padding: 0px 6px 5px 0 !important;
    color: #ffe6d2;
  }
  .mob-text-align {
    text-align: center !important;
  }
  .add-send-total {
    padding: 20px 10px !important;
  }
  .mob-view-flex {
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
  }
  .payment-details-add img {
    margin: 0px 5px;
  }
  .mob-padding-remove {
    padding: 20px 0px !important;
  }
  .order-first {
    order: 2;
  }
  .order-last {
    order: 1;
  }
  .modal-content {
    width: 100% !important;
  }
  .transactions-wrapper {
    padding: 30px 1em !important;
    margin-bottom: 130px;
  }
  .breadcrumb-container {
    width: 100% !important;
    flex-direction: column;
  }
  .user-profile-details {
    flex-direction: column;
    height: 100% !important;
    padding: 10px;
  }
  .about-upesi-header {
    position: inherit !important;
  }
  .text-left {
    text-align: center;
  }
  .text-right {
    text-align: center !important;
  }
  .dashboard-wrapper {
    padding: 30px 0.5em !important;
    margin-bottom: 150px;
  }
  /* h2.resp-accordion { border-bottom: 1px solid #c1c1c1!important; border-top: 0px solid #c1c1c1;} */
  .resp-arrow {
    right: 25px !important;
  }
  .create-account-wrapper {
    height: 100%;
  }
  .btn-padding button {
    width: 100%;
    margin: 5px 0;
  }
  .beneficiary-details-wrapper {
    padding: 20px 10px;
    margin-bottom: 150px;
  }
  .beneficiary-row {
    justify-content: center;
  }
  .height-login-hash {
    height: 100%;
  }
  .footer-wrapper {
    position: relative;
  }
  .footer-wrapper p {
    text-align: center;
  }
  .about-us-banner {
    height: calc(100vh - 266px);
  }
}

/* Custom CSS */
/* h2.resp-accordion { border: 0px;} */
.continue-process a i {
  padding: 0 5px;
}
.dashboard-wrapper {
  padding: 30px 4em;
}
.reg-dob::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.display-flex {
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
}
.tab-width-3 ul li {
  width: 33.33%;
}
#gender-dropdown .dropdown-content {
  height: 82px !important;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.button-align-left {
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: flex-start;
  width: 100%;
}
.justify-content-start {
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: flex-start;
}
.align-items-center {
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  align-items: center;
}
.flex-direction-column {
  flex-direction: column;
}
.custom-link {
  color: rgb(231, 112, 22);
}
.padding-x {
  padding: 0 7px;
}
.transhistory-half-input {
  width: 33.33%;
  padding: 10px;
}
.btn-padding button {
  padding: 13px 20px !important;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 769px) {
  .action-column {
    width: 350px;
  }
}
.btn-padding .btn-action {
  padding: 0.75rem !important;
  /* margin-right: 5px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.promo {
  justify-content: space-between;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  align-items: center;
}
.mob-text-align {
  text-align: right;
}
/* nice select */
.nice-select {
  height: 40px;
  margin: 0 !important;
  color: rgb(144, 144, 144) !important;
  border: 1px solid rgba(54, 54, 54, 0.2) !important;
  padding: 13px !important;
}
.nice-select:after {
  border-bottom: 1px solid rgb(54 54 54 / 90%) !important;
  border-right: 1px solid rgb(54 54 54 / 90%) !important;
  content: "";
}
.nice-select .list {
  background-color: rgb(249, 249, 249) !important;
  border-radius: 4px;
}
.nice-select .option {
  color: rgb(30, 30, 30) !important;
  border-bottom: 1px solid rgba(221, 221, 221, 0.5) !important;
}
.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background: rgb(221, 221, 221) !important;
  color: rgb(30, 30, 30) !important;
  font-weight: 500 !important;
}
.nice-select .list li {
  padding-left: 18px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 29px !important;
  background: transparent !important;
}
.user-type .nice-select {
  margin: 9px 0;
  color: rgb(199, 214, 253) !important;
  border: 1px solid rgb(255, 255, 255, 0.3) !important;
  padding: 12px !important;
}
.profile-phoneno {
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  width: 100%;
  justify-content: space-between;
}
.separator-div {
  position: relative;
  margin-top: 20px;
}
.separator-div p {
  position: absolute;
  top: -10px;
  background: #ffffff;
  padding: 0px 15px;
  font-size: 16px;
}
.a-link {
  font-size: 14px;
  line-height: 23px;
  color: #17306b;
}
.float-right {
  float: right;
}
.div-60 {
  width: 60%;
  float: left;
  margin-top: 10px;
}
.div-40 {
  width: 40%;
  float: left;
  margin-top: 10px;
}
.exchangerate-card {
  border-radius: 10px;
  background: rgb(243, 114, 36);
  background: -webkit-linear-gradient(91deg, rgb(10, 29, 55), rgb(243, 114, 36));
  background: linear-gradient(91deg, rgb(10, 29, 55), rgb(243, 114, 36));
  width: 100%;
  padding: 20px;
  color: white;
}
.td-padding {
  padding: 10px;
}
.currency-card {
  padding: 0px 0px;
}
.currency-card h4 {
  font-size: 18px;
  font-weight: 700;
  padding: 3px 6px 3px 0;
  color: #ffffff;
  max-width: 120px;
  word-break: break-word;
  white-space: break-spaces;
}
.currency-card figure img {
  height: 100%;
}
.currency-card figure {
  float: left;
  width: 22px;
  height: 22px;
}
.dark-blue-bg {
  background: rgb(10, 29, 55);
}
.dark-orange {
  background: rgb(243, 114, 36);
}
.text-orange {
  color: rgb(243, 114, 36) !important;
}

.add-beneficiary-div {
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  padding: 5px;
  flex-direction: column;
}
.add-beneficiary-initial {
  height: 60px;
  width: 60px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 10px;
}
.add-beneficiary-initial span {
  font-size: 20px;
  color: #ffffff;
  line-height: 60px;
  text-align: center;
}
.add-beneficiary-name p:first-child {
  font-size: 14px;
  font-weight: 600;
  color: #1b223a;
}
.add-beneficiary-name {
  text-align: center;
}
.add-beneficiary {
  height: 60px;
  width: 60px;
  border-radius: 10px;
  background: white;
  border: 2px dotted #1b223a8c;
  text-align: center;
  margin-bottom: 10px;
}
.add-beneficiary p {
  font-size: 20px;
  color: #1b223a;
  line-height: 60px;
  text-align: center;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.exchg-rate {
  min-width: 170px;
  margin: 50px 0px;
  width: 100%;
}
.owl-item .slick-slider {
  margin: 0 !important;
}
.exchg-rate p {
  font-size: 14px;
  font-weight: 600;
}
.owl-pagination {
  display: none;
}
.owl-theme .owl-controlls .owl-page span {
  display: block !important;
  width: 8px !important;
  height: 8px !important;
  margin: 5px 5px !important;
}
.acc-summary {
  padding: 20px 10px;
}
.acc-summary ol li {
  padding: 5px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.margin-bottom {
  margin-bottom: 15px;
}
.margin-x {
  margin: 0 15px;
}
.margin-y {
  margin: 5px 0;
}
.order-first {
  order: 1;
}
.order-last {
  order: 2;
}
.text-center {
  text-align: center;
}
.beneficiary-row {
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}
.single-beneficiary {
  border: 1px solid rgb(228, 228, 228);
  border-radius: 7px;
  padding: 15px 5px;
  margin: 0 5px 10px;
  min-width: 150px;
  max-width: 100%;
  min-height: 155px;
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.custom-dropdown {
  display: block;
  position: absolute;
  color: #000000;
  background-color: rgb(249 249 249);
  min-width: 100px;
  z-index: 1;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  left: 22px;
  top: 38px;
  overflow: auto;
}
.custom-dropdown li {
  border-bottom: 1px solid #ededed;
  padding: 8px;
  text-align: center;
  font-family: "gothambook";
  font-size: 14px;
}
.custom-dropdown li:hover {
  border-bottom: 1px solid #ededed;
  padding: 8px;
  text-align: center;
  font-family: "gothambook";
  font-size: 14px;
  background: rgb(221, 221, 221);
}
.font-24 {
  font-size: 20px;
  vertical-align: middle;
}
.margin-top-15 {
  margin-top: 15px;
}
.grey-bg {
  background-color: rgb(247 247 247);
}
.font-size-14 {
  font-size: 14px;
}
.margin-10-20 {
  margin: 10px 20px;
}
.user-profile-image img {
  width: 40px;
  height: 40px;
}
.user-profile-image {
  width: 100% !important;
}
.user-profile-image figure {
  margin: 0 !important;
  width: 100% !important;
  float: left !important;
}
.user-profile-bg {
  height: 60px;
}
.user-profile-details {
  display: inline-flex !important;
  justify-content: space-between !important;
  width: 100% !important;
  align-items: center !important;
  position: relative !important;
}
.breadcrumb-container {
  z-index: 0;
  display: inline-flex;
  justify-content: flex-start;
  width: 15%;
  align-items: center;
}
.about-upesi-header {
  padding: 10px 0 !important;
  position: relative;
}
.user-profile-name {
  width: 100% !important;
  float: left !important;
  text-align: center !important;
  padding: 10px 0 !important;
}
.user-profile-name h3 {
  color: rgba(233 113 22 / 94%);
  font-size: 14px;
}
.user-profile-name h3 a:hover {
  color: #ffffff;
}
.user-profile-name h3 a {
  color: rgb(243, 114, 36);
}
.breadcrumb-container-profile {
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
}
.breadcrumb-container-profile .user-profile-image img {
  width: 80px;
}
.breadcrumb-container-profile .user-profile-name h3 {
  color: rgb(100, 100, 100);
  font-size: 18px;
}

/* The Modal */

/* The Close Button */
.close {
  color: rgb(243, 114, 36);
  float: right;
  font-size: 24px;
  font-weight: normal;
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.modal-header {
  padding: 16px;
}
.modal-body {
  padding: 20px;
  text-align: center;
}
.modal-body h4 {
  color: rgb(243, 114, 36);
  margin-bottom: 20px;
}
.modal-footer {
  padding: 0 20px 20px;
  background-color: #ffffff;
  color: white;
}
.modal {
  top: 0;
  min-width: 250px;
  width: 80%;
  min-height: 200px;
  margin: 0 auto;
  position: fixed;
  z-index: 40001;
  background: #fff;
  box-shadow: 0px 0px 10px #000;
  margin-top: 30px;
  margin-left: 10%;
  animation-name: fadeIn_Modal;
  animation-duration: 0.8s;
}
.modal.hide {
  display: none;
}
.container-backdrop {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  opacity: 0.9;
  background: #000000ba;
  z-index: 40000;
  top: 0;
  left: 0;
  overflow: hidden;
  animation-name: fadeIn_Container;
  animation-duration: 1s;
}

.doc-modal {
  margin-top: 10% !important;
  margin-left: 20% !important;
}

/****MEDIA QUERIES****/
@media screen and (max-width: 768px) {
  .doc-modal {
    margin-top: 25% !important;
    margin-left: 10% !important;
    width: 80% !important;
  }
}

@media screen and (max-width: 500px) {
  .doc-modal {
    margin-top: 25% !important;
    margin-left: 0% !important;
    width: 100% !important;
  }
}

@media screen and (min-width: 768px) {
  .modal {
    width: 600px;
    min-height: 200px;
    margin-left: calc(50vw - 300px);
    margin-top: calc(50vh - 250px);
  }

  /* .doc-modal {
    margin-left: calc(50vw - 450px);
  } */
}

@media screen and (max-width: 500px) {
  .security-details {
    padding: 10px !important;
  }
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }

  .home-wrapper .user-profile-details {
    margin-top: 80px;
  }
  .login-wrapper,
  .contact-banner-prelogin {
    margin-top: 130px;
  }
  .form-control .css-1ru9c3m-Control,
  .form-control .css-hgskgu {
    max-width: 100%;
    width: 100% !important;
    min-width: 250px;
  }
  .dashboard-wrapper > .form-control {
    display: flex;
    float: inherit !important;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  .steps-inputs-column1 {
    margin-top: 10px;
  }
  .beneficiary-row {
    justify-content: center;
  }
  .owl-item {
    width: 100% !important;
  }
  .transaction-table {
    width: 100% !important;
  }
  .pagination-app ul {
    margin-bottom: 0px !important;
  }
  .pagination-app {
    padding: 15px 0 !important;
  }
  .beneficiary-details-wrapper {
    margin-bottom: 30px !important;
  }
  .transaction-table .steps-inputs-column3 button {
    padding: 10px 12px !important;
  }
  .single-contact {
    height: 100% !important;
    width: 100% !important;
    margin: auto !important;
    display: block !important;
    margin-bottom: 10px !important;
  }
  .contact-container {
    display: block !important;
  }
  .single-contact img {
    max-width: 40px;
  }
  .header-wrapper {
    position: fixed !important;
    top: 0;
    right: 0;
    z-index: 99;
  }
  article {
    margin-top: 130;
  }
  .mobile-clear {
    clear: both;
  }
}
/****ANIMATIONS****/
@keyframes fadeIn_Modal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn_Container {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.9;
  }
}

/* terms & condition */

.tnc-container ul li {
  margin-bottom: 15px;
  font-size: 14px;
  list-style: none;
  line-height: 18px;
  text-align: left;
  color: #555555;
  font-family: "gothambook";
}
.otp-container,
.makepayment-container {
  padding: 0px 20px;
}
.otp-container h4 {
  margin-bottom: 0;
}
.makepayment-container h4 {
  margin-bottom: 12px;
}
.cashpickup-bankList li {
  margin-bottom: 12px;
  margin-left: 25px;
  font-size: 14px;
  list-style: auto;
  line-height: 18px;
  text-align: left;
  color: #555555;
  font-family: "gothambook";
}
.owl-theme .owl-controlls .owl-buttons div {
  top: 50% !important;
  transform: translateY(-50%) !important;
}
.owl-theme .owl-controlls .owl-buttons div {
  color: #ffffff00 !important;
}
.acc-summary-page ol li {
  border-bottom: 1px solid #f5f5f5;
}
.acc-summary-page ol li h3 {
  margin: 8px 0px;
}
.owl-theme .owl-controlls .owl-buttons .owl-prev {
  left: -32px !important;
}
.owl-theme .owl-controlls .owl-buttons .owl-next {
  right: -32px !important;
}
.owl-item {
  width: 100% !important;
}
/* contact page */

.single-contact {
  height: 150px;
  width: 33.33%;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  align-items: center;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.contact-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.contact-container p {
  color: rgb(209, 209, 209);
  font-weight: 500;
}
.contact-container a {
  color: rgb(209, 209, 209);
}
.contact-container a:hover {
  color: rgb(255, 255, 255);
}
.contact-banner-image {
  width: 40%;
  float: right;
}
.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}
.pointer {
  cursor: pointer;
}
.passwordRed {
  height: 5px;
  margin-top: 20px;
  margin-bottom: -15px;
  background-color: red;
}
.passwordAmber {
  height: 5px;
  margin-top: 20px;
  margin-bottom: -15px;
  background-color: orange;
}
.passwordGreen {
  height: 5px;
  margin-top: 20px;
  margin-bottom: -15px;
  background-color: green;
}
.error-text {
  color: #c71e2d;
  margin: 0;
  text-align: left;
  font-size: 14px;
}
.modal-body {
  min-width: 25em;
  text-align: center;
  line-height: 3rem;
}
.modal-body .otpInput {
  width: 100%;
  padding: 11px;
  border: 1px solid rgba(54, 54, 54, 0.2);
  border-radius: 4px;
}
.modal-body .otpButton {
  background-color: rgb(231, 112, 22);
  padding: 13px 40px;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  font-size: 13px;
  transition: all 0.9s ease 0s;
  outline: none;
  text-decoration: none;
  font-family: gothammedium, sans-serif;
  border: 0;
  display: block;
  text-align: center;
  margin: 0 auto;
}
.modal-body .expiry-label {
  min-width: 100%;
  text-align: left;
  font-size: 12px;
  margin: 0 0 2em 0.2em;
  line-height: 1.5;
}
.ReactModal__Content {
  min-width: calc(100vw - 936px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden !important;
}
.alert {
  padding: 1rem 1rem;
  margin-top: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
/* This is the submit button styles */
.load-button {
  overflow: hidden;
  cursor: pointer;
  position: relative;
  transition: all 1s !important;
}
.spin .spinner {
  right: 2em;
  top: 0.4em;
  width: 2.5em;
  display: block;
  position: absolute;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spin .spinner::before {
  content: "";
  width: 2em;
  height: 2em;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border: solid 0.35em rgb(255, 255, 255); /* Thickness/color of spinner track */
  border-bottom-color: rgb(190, 178, 178); /* Color of variant spinner piece */
  animation: 0.8s linear infinite spinner; /* speed of spinner */
  transform: translate(-50%, -50%);
  will-change: transform;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
/* .section {
  color: #fff;
  width: 100%;
  float: left;
  background-image: url("../images/preloader-bg.jpg");
  text-align: center;
  background-position: center top;
  padding: 30px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
} */
button.verify-otp {
  display: flex;
  border: none;
  transition: all 0.9s ease 0s;
  background-color: rgb(199, 214, 253);
  color: rgb(27, 34, 58);
  border-radius: 4px;
  padding: 13px 40px;
  font-size: 13px;
}
button.verify-otp:hover {
  background-color: rgb(27, 34, 58);
  color: rgb(199, 214, 253);
}
.mouse-not-allowed {
  cursor: not-allowed;
  opacity: 0.8;
}
.mouse-not-allowed:hover {
  background-color: rgb(227, 227, 227) !important;
  color: rgb(102, 102, 102) !important;
}
.mouse-not-allowed.primary:hover {
  background-color: rgb(231, 112, 22) !important;
  color: rgb(255, 255, 255) !important;
}
.close-btn {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  outline: none;
  border: none;
  background-color: #fff;
  font-size: 1.3rem;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.loader {
  margin: 0 auto 2rem;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #f0802b; /* Orange */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

input:disabled {
  background-color: lightgrey;
}

.mainDiv {
  position: fixed;
  top: -133px;
  bottom: 0;
  width: 100vw;
  height: calc(100vh + 133px);
  background: #000;
  z-index: 1111;
  opacity: 0.5;
}
.loading {
  width: 50px;
  height: 50px;
  border: 3px solid rgb(255 255 255 / 30%);
  border-radius: 50%;
  border-top-color: rgb(247 108 3);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1112;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.owl-wrapper {
  max-width: 100%;
}
.input-container {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.password-icon {
  padding: 10px;
  background: darkgrey;
  color: orangered;
  min-width: 50px;
  text-align: center;
  border-radius: 0px 4px 4px 0px;
}

.login-wrapper .password-input,
.create-account-wrapper .password-input {
  position: relative;
}

.login-password-icon {
  padding: 10px;
  right: 0;
  top: 16px;
  background: none;
  color: white;
  min-width: 50px;
  text-align: center;
  border-radius: 0px 4px 4px 0px;
  position: absolute;
  /* color: var(--primary); */
}

.dashboard-wrapper .form-control {
  display: flex;
  float: right;
  width: auto;
  border: none !important;
}
.about-us-banner {
  height: calc(100vh - 133px);
}
.css-6j8wv5-Input {
  display: contents !important;
}
.css-1r8daa7-Control {
  border-radius: 4px;
}
.css-1f43avz-a11yText-A11yText {
  text-align: left;
}
.css-1s2u09g-control,
.css-1pahdxg-control {
  text-align: left;
}
.css-319lph-ValueContainer,
.css-1d8n9bt {
  padding: 0px 8px !important;
}
.css-ackcql,
.css-1d8n9bt {
  margin: 0 !important;
  padding: 0 !important;
}

.sendMoney-mobile-show {
  display: none;
}
.sendMoney-mobile-show {
  display: none;
}
.tab-2 ul > li.resp-tab-item {
  width: 50% !important;
}
.tab-2 {
  width: 100% !important;
}
.slick-dots {
  display: none;
}

.custom-grid-size {
  display: flex;
  clear: both;
}
.custom-grid-size .w-50 {
  width: 50%;
  border-top: none;
}
.custom-grid-size .w-50:first-child {
  max-width: 50%;
  border-right: 1px solid #ccc;
  margin-right: 5px;
  padding-right: 5px;
}

.not-found {
  background-color: #111c38;
  color: #fff;
  font-size: 100%;
  line-height: 1.5;
  padding: 0 0 2rem 0;
}
.not-found h1 {
  text-align: center;
  font-size: 15em;
  font-weight: 100;
}
.not-found p {
  font-size: 2em;
  text-align: center;
  font-weight: 100;
  margin-bottom: 2rem;
}
.not-found a {
  color: #fff;
  background-color: rgb(231, 112, 22);
  font-size: 1.2em;
  border: none;
  padding: 0.5em;
  border-radius: 6px;
  margin: 0 auto;
  transition: all 0.3s linear;
}

.date-error {
  border: 1px solid red;
  margin-top: 25px;
  border-radius: 4px;
  height: 40px;
}

.date-picker {
  margin-top: -17px;
}
.register-date-error {
  margin-top: 20px;
  position: relative;
  top: 0px;
  left: 0;
  border: 1px solid red;
  border-radius: 4px;
  height: 40px;
}

.css-1f43avz-a11yText-A11yText,
.css-1pahdxg-control,
.css-1s2u09g-control {
  text-align: left;
  padding: 0 5px;
}
.transaction-table .steps-inputs-wrapper button i {
  /* float: right; */
  padding: 0px 0px 0px 15px !important;
}
/* .steps-inputs-column3 button{margin:5px !important} */

.kyc-message {
  position: relative;
  top: 90px;
  left: 0;
  font-size: 17px;
  font-weight: 700;
}

/* slider css start */
/* .landing-banner-slider .bg-slick-custom-e1 {
  background-image: url(../images/landing-slider-bg1.jpg);
  background-color: rgb(231, 112, 22);
  float: left;
  width: 100%;
  position: relative;
} */

/* .landing-banner-slider .bg-slick-custom-e2 {
  background-image: url(../images/landing-slider-bg2.jpg);
  background-color: rgb(26, 34, 58);
  float: left;
  width: 100%;
  position: relative;
} */

.bg-slick-custom-e2 .slider-copy {
  background-color: transparent !important;
}
.slider-copy {
  position: absolute;
  top: 0px;
  left: 90px;
  bottom: 0px;
  width: 40%;
}

.slider-vertical {
  display: table-cell;
  vertical-align: middle;
}

/* slider css end */

/* Pdf Format Css */
.pdf-view img {
  -ms-interpolation-mode: bicubic;
}

.pdf-view li {
  list-style: none;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
}

.pdf-view ul {
  padding: 0;
  margin: 0;
}

.pdf-view table[class="full"] {
  padding: 0 !important;
  border: none !important;
}

.pdf-view table.receipt-details tr td {
  border: 1px solid #818ea6;
  padding: 9px;
  font-size: 12px;
}

.pdf-view table td img[class="imgresponsive"] {
  width: 100% !important;
  height: auto !important;
  display: block !important;
}

.pdf-view h1 {
  font-size: 18px;
  text-transform: uppercase;
  color: #1a2139;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  padding: 16px 0 0 0;
}
.pdf-view h4 {
  color: #1a2139;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  margin: 20px 0;
}
.pdf-view .upesiLogo {
  max-width: 85px;
  margin: 25px;
}

.pdf-view h5 {
  color: #ff8f31;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
}

.pdf-view p {
  color: #1a2139;
  font-family: "Open Sans", sans-serif;
  text-align: left;
  font-size: 13px;
  padding: 10px 0;
}

.pdf-view a {
  text-align: center;
  color: #1a2139;
  text-transform: uppercase;
  padding: 0px 2px;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  margin: auto;
  /*background-color: #e26500;*/
  font-size: 12px;
  transition: all 0.9s ease;
}

.pdf-view a:hover {
  /*color: #ffffff;*/
  /*background-color:#ffffff;*/
  transition: all 0.9s ease;
  text-decoration: underline;
}
.pdf-view span {
  font-weight: 100;
}

@media only screen and (max-width: 800px) {
  .pdf-view table[class="full"] {
    width: 100% !important;
  }

  .pdf-view table[class="devicewidth"] {
    width: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .pdf-view table td img.responsiveimg {
    width: 100% !important;
    height: auto !important;
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .pdf-view table[class="devicewidth"] {
    width: 100% !important;
  }

  .pdf-view table[class="inner"] {
    width: 100% !important;
    text-align: center !important;
    clear: both;
  }

  .pdf-view table td a[class="top-button"] {
    width: 160px !important;
    font-size: 14px !important;
    line-height: 37px !important;
  }

  .pdf-view table td[class="readmore-button"] {
    text-align: center !important;
  }

  .pdf-view table td[class="readmore-button"] a {
    float: none !important;
    display: inline-block !important;
  }

  .pdf-view .hide {
    display: none !important;
  }

  .pdf-view .hidden {
    display: none !important;
  }

  .pdf-view table td[class="smallfont"] {
    border: none !important;
    font-size: 22px !important;
  }

  .pdf-view table td[class="sidespace"] {
    width: 10px !important;
  }
}

@media only screen and (max-width: 480px) {
  .pdf-view table {
    border-collapse: collapse;
    width: 100vw;
  }
  .pdf-view table td[class="template-img"] img {
    width: 100% !important;
    display: block !important;
  }
  .slick-slider {
    margin-top: 135px;
  }
  .slider-copy {
    position: relative !important;
    top: 0px;
    left: 0px !important;
    bottom: 0px;
    width: 100% !important;
    width: 40%;
    min-height: 112px;
    vertical-align: middle;
  }
}

.payment-list {
  padding: 5px;
}

.landing-display-caption {
  height: 100%;
  display: table !important;
  width: 100%;
}
.landing-slider-vertical {
  display: table-cell !important;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .reset-header-form {
    margin-top: 130px;
  }

  /* .login-wrapper {  height: calc(100vh - 1px)!important;} */
  .register-container {
    margin-top: 130px;
  }
  /* .conatct-us-banner {
    margin-top: 132px;
  } */
  .contact-banner-image {
    width: 100% !important;
    float: right;
  }

  .conatct-us-banner .slider-copy {
    position: relative;
    top: 0px;
    left: 0;
    bottom: 0px;
    width: 100%;
  }
}

.security-lists-details {
  width: 100%;
}
.security-details {
  width: 100%;
  float: left;
  padding: 70px;
}
.security-details p {
  clear: both;
  width: 100%;
  padding: 10px 0;
  color: #6e6e6f;
  text-align: left;
}
.security-details h4 {
  color: #1a223a;
  padding: 20px 0 0px 0;
  width: 100%;
  text-align: left;
  float: left;
  text-transform: capitalize;
}

.preloginSendmoney ul.upesi_resp-tabs-list li.resp-tab-item.resp-tab-active,
.preloginSendmoney ul.upesi_resp-tabs-list li.resp-tab-item.react-tabs__tab--selected {
  background: #ffffff !important;
  border-bottom: 3px solid #f37224;
}
.preloginSendmoney .upesi_grey_bg {
  background: #f4f4f4ad;
}
.preloginSendmoney .upesi_section_padding {
  padding: 50px 0 80px;
}
.preloginSendmoney .upesi-header-title h3 span {
  background-color: rgb(248 248 248);
}
.preloginSendmoney .resp-tab-content-active {
  display: block;
  background: #ffffff;
  box-shadow: 0px 20px 70px rgb(208 208 208 / 25%);
}
.preloginSendmoney ul.upesi_resp-tabs-list li.resp-tab-active h5,
.preloginSendmoney ul.upesi_resp-tabs-list li.react-tabs__tab--selected h5 {
  color: #f37224;
}
.preloginSendmoney ul.upesi_resp-tabs-list li {
  border-bottom: 3px solid #c7d6fd;
  background-color: #ffffff;
}

.preloginSendmoney ul.upesi_resp-tabs-list li h5 {
  color: #192742;
  padding: 15px 0px 0px;
}
/* .preloginSendmoney .send-money-tab2 ul.upesi_resp-tabs-list li:nth-child(1) span { background-image: url("images/money-process-icon1-hover.png"); }
.preloginSendmoney .send-money-tab2 ul.upesi_resp-tabs-list li:nth-child(2) span { background-image: url("images/money-process-icon2-hover.png"); }
.preloginSendmoney .send-money-tab2 ul.upesi_resp-tabs-list li:nth-child(3) span { background-image: url("images/money-process-icon3-hover.png"); }
.preloginSendmoney .send-money-tab2 ul.upesi_resp-tabs-list li:nth-child(4) span { background-image: url("images/money-process-icon4-hover.png"); } */
.upesi-breadcrumb-bg {
  width: 100%;
  float: left;
  height: 200px;
  background-image: url("../images/account-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* NEW CSS ADDED */

/* .upesi_logo-wrapper{
    margin: 0!important;
} */

.upesi_header_dropbtn {
  background: #1a223a !important;
  border-radius: 6px;
  padding: 6px 20px;
  min-width: 190px;
  color: #ffffff !important;
}

.upesi_header_dropbtn h5 {
  color: #ffffff !important;
}

.dropdown .upesi_header_dropbtn span.value {
  color: #ffffff !important;
}

.upesi_header-country {
  width: 15%;
  padding: 20px 0px 0px;
  float: right;
}

.upesi_header-account-wrapper {
  width: 25%;
}

.upesi_desktop-lists ul {
  text-align: right;
}

.upesi_bottom-footer-wrapper {
  width: 100%;
  float: left;
  padding: 6px 35px;
}

.upesi_footer-copyright {
  width: 50%;
  float: right;
}

.upesi_footer-copyright p {
  text-align: right;
}

.upesi-breadcrumb-bg {
  width: 100%;
  float: left;
  height: 200px;
  background-image: url("../images/account-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* code paste by chirag  15/7/22 3:07 am */

.upesi_content {
  font-weight: 400;
  font-size: 14px;
  color: #7a7a7a !important;
  margin-bottom: 10px;
}
.upesi_alink {
  color: #f37224;
  font-weight: 400;
  font-size: 14px;
}
.upesi_hw_section_padding {
  padding: 50px 30px 80px;
}
ul.steps_to_transfer_money {
  margin: 0;
  padding: 20px 0;
  margin-left: 30px;
}

ul.steps_to_transfer_money li {
  line-height: 22px;
  font-weight: 400;
  font-size: 14px;
  color: #1a223a;
  font-family: "gothambook";
  margin-bottom: 10px;
}

.upesi_video_box {
  padding: 0 20px;
}
.faqbanner {
  background: url("../images/faqs-banner.jpg");
}
ul.upesi_about_right {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul.upesi_about_right li {
  background: #1a223a;
  box-shadow: 0px 10px 20px rgba(208, 208, 208, 0.24);
  border-radius: 7px;
  padding: 20px;
  width: 100%;
  min-height: 130px;
  margin-bottom: 30px;
}

ul.upesi_about_right li h6 {
  font-family: "Gotham";
  font-weight: 700;
  font-size: 22px;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 10px;
}
ul.upesi_about_right li p {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}
ul.upesi_about_right .upesi_about_right_single {
  display: flex;
  align-items: flex-start;
}
ul.upesi_about_right .about-icon {
  margin-right: 20px;
}

ul.upesi_contact_right {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 40px;
}
ul.upesi_contact_right li {
  background: #c7d6fd;
  box-shadow: 0px 10px 20px rgba(208, 208, 208, 0.24);
  border-radius: 7px;
  padding: 20px;
  width: 100%;
  min-height: 120px;
  margin-bottom: 10px;
}

ul.upesi_contact_right li h6 {
  font-family: "Gotham";
  font-weight: 700;
  font-size: 22px;
  text-transform: capitalize;
  color: #1a223a;
  margin-bottom: 10px;
}
ul.upesi_contact_right li p {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #6a6a6a;
}
ul.upesi_contact_right .upesi_contact_right_single {
  display: flex;
  align-items: flex-start;
}
ul.upesi_contact_right .contact-icon {
  margin-right: 20px;
}
.banner {
  width: 100%;
}
label {
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}
/* .div-50{max-width: 50%;width: 100%} */

.card2 {
  box-shadow: 2px 5px 8px 6px #8888887a;
  max-width: 500px;
  border-radius: 20px;
  margin: 0px auto;
  padding: 0px;
  min-height: 350px;
}
.card2 .card {
  min-width: 400px;
  max-width: 400px;
  padding: 50px 35px;
  width: 100%;
}
.card2 .card ul li {
  margin-top: 05px;
}
.contact .email-input input,
.email-input select,
.email-input textarea {
  min-height: 40px;
  background: #f1f5fe;
  margin-bottom: 26px;
  border: 1px solid #c7d6fd;
  border-radius: 6px;
  width: 100%;
  padding: 13px;
}
.contact section {
  clear: both;
}
.contact .email-input input::placeholder,
.email-input textarea::placeholder {
  color: #0a1d37;
  font-size: 12px;
}

.nice-select {
  color: #000;
  border-color: rgb(255 255 255 / 39%);
  background: rgb(199 214 253);
  margin-bottom: 26px;
}
.continue-process a {
  width: 100%;
  display: BLOCK;
}
.continue-process {
  width: 100%;
  display: BLOCK;
  float: none !important;
}

section {
  clear: both;
}
.contact-title {
}
.contact-title h3 {
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  margin-top: 40px;
}
.contact-title p {
  display: block;
  text-align: center;
}
.upesi-breadcrumb-bg {
  float: none;
}

/* contact page */

.single-contact {
  height: 150px;
  /* width: 33.33%;     */
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  align-items: center;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.contact-container {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.contact-container p {
  color: rgb(209, 209, 209);
  font-weight: 500;
}
.contact-container a {
  color: rgb(209, 209, 209) !important;
  text-decoration: none !important;
}
.contact-container a:hover {
  color: rgb(255, 255, 255);
}
.contact-banner-image {
  width: 40%;
  float: right;
}

/* blog */

.banner {
  width: 100%;
}
label {
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}
.blog-date {
  background: #f37224 !important;
  border: 1px solid #ffffff;
  padding: 8px;
  position: absolute;
  top: -16px;
  right: 15px;
  font-size: 12px;
  color: #ffffff !important;
  border-radius: 3px;
}

.email-input input,
.email-input select {
  height: 40px; /* background: #C7D6FD; */
  margin-bottom: 26px;
}
section {
  clear: both;
}
.nice-select {
  color: #000;
  border-color: rgb(255 255 255 / 39%);
  background: rgb(199 214 253);
  margin-bottom: 26px;
}
.bloglist {
  display: flex;
  margin: 50px auto;
}
.bloglist li {
  margin: 50px;
  max-width: 25%;
  width: 100%;
}
.home-choise li figure {
  background: #c7d6fd;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
}
.home-choise li figure img {
  max-width: 100px;
}
.home-choise li figure p {
  font-size: 17px;
  line-height: 29px;

  text-align: center;
  text-transform: capitalize;

  color: #1a223a;
}
.home-choise li {
  max-width: 33.33%;
  width: 100%;
}
h4 {
  /* width: 324.05px; */
  /* height: 29px; */
  /* left: 92px; */
  /* top: 889px; */
  font-family: "Gotham";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 29px;

  /* identical to box height, or 171% */
  text-transform: capitalize;
  text-align: left;
  color: #1a223a;
}
.media-caption p {
  text-align: left;
}
.media-caption h5 {
  color: #f37224 !important;
  text-align: left;
  font-size: 12px;
}

/* how work */

.upesi_content {
  font-weight: 400;
  font-size: 14px;
  color: #7a7a7a !important;
  margin-bottom: 10px;
}
.upesi_alink {
  color: #f37224;
  font-weight: 400;
  font-size: 14px;
}
.upesi_hw_section_padding {
  padding: 50px 30px 80px;
}
ul.steps_to_transfer_money {
  margin: 0;
  padding: 20px 0;
  /* margin-left: 30px; */
}

ul.steps_to_transfer_money li {
  line-height: 22px;
  font-weight: 400;
  font-size: 14px;
  color: #1a223a;
  font-family: "gothambook";
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
}
ul.steps_to_transfer_money li p {
  margin-left: 15px;
}

/* ul.steps_to_transfer_money li:nth-child(1){      list-style-image: url('images/1.svg');    }
ul.steps_to_transfer_money li:nth-child(2){      list-style-image: url('images/2.svg');    }
ul.steps_to_transfer_money li:nth-child(3){      list-style-image: url('images/3.svg');    }
ul.steps_to_transfer_money li:nth-child(4){      list-style-image: url('images/4.svg');    }
ul.steps_to_transfer_money li:nth-child(5){      list-style-image: url('images/5.svg');    } */
.upesi_video_box {
  padding: 0 20px;
}

/* forex */
.forex .banner {
  width: 100%;
}
.forex label {
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}
.forex .div-50 {
  max-width: 500px;
  border-radius: 20px;
  margin: 40px auto;
  padding: 30px;
  min-height: 350px;
}
.forex .email-input input,
.forex .email-input select {
  height: 40px;
  background: #c7d6fd;
  margin-bottom: 26px;
}
.forex .forex section {
  clear: both;
}
.forex .nice-select {
  color: #000;
  border-color: rgb(255 255 255 / 39%);
  background: rgb(199 214 253);
  margin-bottom: 26px;
}
.forex .home-choise {
  display: flex;
  margin: 50px auto;
}
.forex .home-choise li figure {
  background: #c7d6fd;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
}
.forex .home-choise li figure img {
  max-width: 100px;
}
.forex .home-choise li figure p {
  font-size: 17px;
  line-height: 29px;

  text-align: center;
  text-transform: capitalize;

  color: #1a223a;
}
.forex .home-choise li {
  max-width: 25%;
  width: 100%;
}

/* .howitwork ul.steps_to_transfer_money li:nth-child(1){background: url('images/1.svg');    }
.howitwork ul.steps_to_transfer_money li:nth-child(2){background: url('images/2.svg');    }
.howitwork ul.steps_to_transfer_money li:nth-child(3){background: url('images/3.svg');    }
.howitwork ul.steps_to_transfer_money li:nth-child(4){background: url('images/4.svg');    }
.howitwork ul.steps_to_transfer_money li:nth-child(5){background: url('images/5.svg');    } */
.howitwork ul.steps_to_transfer_money li svg {
  min-width: 25px;
  height: 25px;
}
/* forex */
.forex .banner {
  width: 100%;
}
.forex label {
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
  text-align: left;
}
.forex .div-50 {
  max-width: 500px;
  border-radius: 20px;
  margin: 40px auto;
  padding: 30px;
  min-height: 350px;
}
.forex .email-input input,
.forex .email-input select {
  height: 40px;
  background: #c7d6fd;
  margin-bottom: 26px;
}
.forex .forex section {
  clear: both;
}
.forex .nice-select {
  color: #000;
  border-color: rgb(255 255 255 / 39%);
  background: rgb(199 214 253);
  margin-bottom: 26px;
}
.forex .home-choise {
  display: flex;
  margin: 50px auto;
}
.forex .home-choise li figure {
  background: #c7d6fd;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
}
.forex .home-choise li figure img {
  max-width: 100px;
}
.forex .home-choise li figure p {
  font-size: 17px;
  line-height: 29px;

  text-align: center;
  text-transform: capitalize;

  color: #1a223a;
}
.forex .home-choise li {
  max-width: 25%;
  width: 100%;
}

.partners-slider .slick-slide {
  margin: 5px;
}
.partners-slider .slick-slide.slick-cloned:last-child {
  display: none;
}
/* .upesi_video_box .video-react-controls-enabled  .video-react{max-width: 100%;padding-top: 20px!important;} */
/* .video-react-controls-enabled {padding-top: 0px!important;} */
/* .upesi_video_box video{max-width: 400px;border-radius: 10px;margin-top: 35px;}
.video-react-control-bar {position: absolute;width: 100%;background: #ffffff;font-size: 12px;display: flex;bottom:0;  max-width: 400px;
  padding: 5px;
} */
/* .video-react-controls-enabled {position: relative;} */
.pagination-app {
  width: 100%;
  float: left;
  padding: 30px 0px;
}
.pagination-app ul {
  width: 100%;
  float: left;
  text-align: center;
  margin: auto;
}
.pagination-app ul li {
  display: inline-block;
}
.pagination-app ul li a {
  font-size: 12px;
  width: 30px;
  height: 30px;
  float: left;
  text-align: center;
  border: 1px solid rgb(234, 234, 234);
  border-radius: 50%;
  padding: 8px 0px 0px;
  color: rgb(68, 68, 68);
}
.pagination-app ul li a:hover {
  background-color: rgb(68, 68, 68);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(68, 68, 68);
}
.pagination-app ul li a.active {
  background-color: rgb(68, 68, 68);
  color: rgb(255, 255, 255);
}
.jvectormap-container svg g {
  display: contents;
}
.jvectormap-container {
  min-height: 400px;
  height: 100%;
}
.jvectormap-container svg {
  width: 80%;
}
.feedback-custom-form textarea {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(54, 54, 54, 0.2);
  border-radius: 6px;
  padding: 13px;
  color: rgb(25, 38, 66);
  font-size: 12px;
  resize: none;
  width: 100%;
  float: left;
  height: 130px;
  margin: 15px 0 0;
}

.feedback-custom-form .nice-select {
  margin: 15px 0 0 !important;
  color: rgb(25, 38, 66) !important;
}
.feedback-custom-form .border-bottom {
  border-bottom: 1px solid #f5f5f5;
}
.feedback-custom-form ol li h6 {
  line-height: 22px;
  text-align: left;
}
.row-content-text {
  text-align: left;
}
.row-content-text p,
.row-content-text ul li {
  font-weight: 400;
  font-size: 14px;
  color: #7a7a7a !important;
  margin-bottom: 0px;
}
.row-content-text p:last-child,
.row-content-text ul li {
  margin-bottom: 5px;
}
.row-content-text h5 {
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
}
.row-content-text ul li {
  list-style-type: disc;
}
.row-content-text .countrylistul li {
  display: inline list-item;
}
.row-title {
  font-family: "gothambook", sans-serif;
  color: var(--row-title-color, #0000009e);
  font-size: 16px !important;
}
.row-content-text ul {
  padding-left: 15px;
}
.media-caption h4 {
  line-height: 20px;
  text-align: left;
}
.media-caption {
  padding: 20px;
}

.alert-box-req-mon {
  width: 100%;
  min-height: 2rem;
  background-color: #f08021;
  border-radius: 5px;
  margin-bottom: 1rem;
  margin-left: 9px;
  line-height: 2rem;
  padding: 0 0.5rem 0 0.5rem;
  font-weight: 600;
  color: white;
}

.alert-box-req-mon-btn {
  background-color: rgba(0, 0, 0, 0);
  color: #0a1d37;
  text-decoration: underline;
  font-weight: 600;
  font-size: 0.9rem;
  margin: 0;
  padding: 0;
}

.request-money-button {
  background-color: #0a1d37 !important;
}
.request-money-button:hover {
  background-color: #f08021 !important;
}

@media only screen and (max-width: 915px) {
  .alert-box-req-mon {
    font-size: 0.8rem;
  }
  .alert-box-req-mon-btn {
    font-size: 0.8rem;
  }
}

.req-success p {
  padding: 0;
  color: #000;
  text-align: left;
}

/* Login Page */

.login-container {
  background: linear-gradient(to top, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.6)), url("../images/map-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.login-Card {
  border-radius: 10px !important;
  box-shadow: 0 4px 34px 0 rgba(0, 0, 0, 0.25);
  flex: 0 0 25%;
  border: none !important;
}

.login-login-btn {
  background: var(--primary) !important;
  color: var(--black) !important;
}

.login-primary-Color {
  color: var(--primary) !important;
}

.login-inner-Card text {
  font-size: 14.331px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* Reset Password -Redesign */

.resetPassword-desc {
  color: #000;
  font-size: 24px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.reset-Password-title {
  color: #001046;
  font-size: 48px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.password-reset-btn {
  border-radius: 7.165px;
  background: #f28021;
  color: #fff;
  font-size: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.reset-password {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 500;
  line-height: 72px;
  letter-spacing: 0em;
  /* text-align: left; */
  color: #001046;
}

.reset-password-desc {
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.terms-privacy {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.terms-privacy span {
  color: #f28021;
}

::-ms-reveal {
  display: none;
}

.password-container {
  position: relative;
}
.password-container input[type="password"],
.password-container input[type="text"] {
  padding-right: 20px;
}

.password-container .toggle-password {
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
  color: var(--primary) !important;
}

.success-reset {
  /* border-radius: 360px; */
  background: #f28021;
}

.form-input {
  border-radius: 7.165px;
  border: 1.024px solid rgba(15, 28, 56, 0.5);
  background: #f5f5f5;
}
.reset-btn-submit {
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* background-color: var(--primary) !important; */
  border-radius: 7.165px;
  background: #f28021;
  height: 58px;
}
