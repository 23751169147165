.EX_Rate__Card {
  border-radius: 30px !important;
  box-shadow: 0 4px 34px 0 rgba(0, 0, 0, 0.25);
  flex: 0 0 40%;
  border: none !important;
  /* font-size: 62.5%; */
}

.EX_Rate__Card .card_header {
  background: var(--dark-blue);
  color: var(--white);
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
  padding: 1.5rem;
}

.card_header__title {
  font-family: inherit !important;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3.75rem;
}

.form {
  padding: 1.5rem;
}

.info {
  /* font-size: 1rem !important; */
}

.ex_rate__btn {
  background: var(--primary) !important;
  color: var(--black) !important;
  border: var(--primary) !important;
  padding: 1.5rem !important;
  width: 100%;
  margin-top: 1rem;
  font-weight: 700 !important;
  font-size: 1.25rem !important;
  line-height: 1.875rem !important;
}

.ex_rate__btn:focus {
  outline: none;
}

.ex_rate__btn:hover {
  color: var(--white) !important;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.246);
}

.select {
  border: var(--border-dark-gray) !important;
  border-left: none !important;
  width: 45%;
}

/* .select .dropdown-menu {
    height: 400px !important;
    overflow-y: scroll !important;
  } */

@media (max-width: 1600px) {
  .EX_Rate__Card {
    /* flex: 0 0 30%; */
  }
}
@media (max-width: 1300px) {
  .EX_Rate__Card {
    /* flex: 0 0 50%; */
  }
  .EX_Rate__Card .card_header {
    padding: 1.2rem;
  }

  .card_header__title {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .ex_rate__btn {
    padding: 1.2rem !important;
  }
}

@media (max-width: 992px) {
  .EX_Rate__Card {
    flex: 0 0 50%;
  }
}

@media (max-width: 768px) {
  .EX_Rate__Card {
    flex: 0 0 auto;
    /* margin-top: -10rem;
      flex: 0 0 auto; */
  }

  .EX_Rate__Card .card_header {
    padding: 1.5rem;
  }
  .ex_rate__btn {
    padding: 0.875rem !important;
  }
}

@media (max-width: 540px) {
  .EX_Rate__Card {
    flex: 1;
  }

  .EX_Rate__Card .card_header h1 {
    font-size: 1.5rem !important;
    line-height: 1.5rem !important;
  }

  .form {
    padding: 0.875rem;
  }
}

@media (max-width: 280px) {
  .EX_Rate__Card {
    flex: 0 0 100%;
  }

  .card_header {
    padding: 0.875rem !important;
  }

  /* .EX_Rate__Card .card_header h1 {
    font-size: 1.2rem !important;
    line-height: 1rem !important;

  } */

  /* .EX_Rate__Card .card_header h1 {
    font-size: 1.2rem !important;
    line-height: 1rem !important;
  } */

  .form {
    padding: 0.25rem !important;
  }

  .info {
    font-size: 0.875rem !important;
  }

  .ex_rate__btn {
    padding: 0.2rem !important;
    font-size: 1.1rem !important;
  }
}

@media (max-height: 1000px) {
  .EX_Rate__Card {
    /* margin-top: -2rem; */
  }
}

@media (max-height: 950px) {
  .EX_Rate__Card {
    margin-top: 0;
  }
}

@media (max-height: 850px) {
  /* .ex_rate__btn {
      padding: 0.875rem !important;
    } */
  .form {
    padding: 0.875rem;
  }
}

@media (min-height: 700px) and (max-height: 750px) {
  .EX_Rate__Card {
    /* height: 65vh !important; */
  }
}
