.B_to_B {
  width: 100%;
}

/* TODO: reuse this */
.B_to_B__title {
  color: var(--primary);
  padding-bottom: 1rem;
  font-weight: 800;
  font-size: 3rem;
  line-height: 4.5rem;
  font-family: inherit;
}

.B_to_B__text {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.btob_btn {
  background: var(--secondary) !important;
  border: var(--secondary) !important;
  color: var(--primary) !important;
  font-size: 2.25rem !important;
  line-height: 3.375rem;
  font-weight: 700;
  font-family: inherit;
  margin: 1.5rem auto 0 !important;
  width: 30% !important;
}

@media (max-width: 992px) {
  .btob_btn {
    width: 50% !important;
  }
  .B_to_B {
    width: 100%;
  }
  .B_to_B__text {
    padding: auto;
  }
}

@media (max-width: 540px) {
  .B_to_B {
    margin: 1.5rem auto !important;
  }

  .B_to_B__title {
    font-size: 2rem;
    line-height: 2rem;
    width: 100% !important;
  }
  .B_to_B__text {
    font-size: 1.19rem;
    line-height: 2rem;
  }

  .btob_btn {
    font-size: 1.29rem !important;
    line-height: 2rem;
    width: 90% !important;
    margin: 0 auto !important;
  }
}

@media (max-width: 280px) {
  .B_to_B__title {
    font-size: 1.4rem;
  }
}

/* w-25 mt-4 p-2
mt-5 pt-5 */
