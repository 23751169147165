#list li {
    list-style: square !important;
    list-style-position: inside !important;
    list-style-image: linear-gradient(to top, #f28021, #f28021) !important;
    padding: 0.7rem 0;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
  }
  
  .ug_right__bg {
    background: url("../../../../assets/images/UG-signup-bg.png");
    width: 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  