.whyUs_ug_div {
    position: relative;
    margin-top: 70vh;
  }
  .spacer_div{
    position: relative;
    margin-top: 80vh;
  }

  .padding-left-ug-7{
    padding-left: 15% !important;
    width: 90% !important;
  }



  @media only screen and (max-width: 767px) {
    .whyUs_ug_div {
        margin-top: 115vh;
      }
      .spacer_div{
        margin-top: 115vh;
      }
  }