.countries {
  background: #ffffff;
  padding: 3rem 0;
  margin-top: 2rem !important;
}

.countries__title {
  font-weight: 800;
  font-size: 2.25rem;
  line-height: 3.375rem;
  color: #0f1c38;
  font-family: inherit;
}

.countries__sub_title {
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 2.25rem;
  color: #000000;
  font-family: inherit;
  padding-bottom: 1.5rem;
}

.countries__list {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  font-family: Poppins;

  /* background-color: pink; */
}
.country_name {
  margin-top: "5px";
}



.operating_countries_flag_sprite {
  background-image: url("../../../../assets/images/operatingCountries/css_sprites.png") !important;
  background-repeat: no-repeat;
  display: inline-block;
  height: 50px;
  width: 70px;
  margin: 2px 7px;
}
