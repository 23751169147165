

  .container-overlay {
    z-index: 1;
  }


  

  .container-overlay:hover .closIcon {
    display:  flex !important;
    cursor: pointer;

    /* background-color: var(--primary); */
  }

  @media only screen and (max-width: 600px) {
    .videoIframe {
      width :auto !important;
      height:auto !important;  

    } 
    }

  
  
